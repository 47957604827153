import React from "react";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";

const FileUploadComponent = ({
    label,
    sx,
    projectName,
    fileType,
    currentYear,
    onChange,
}) => {
    let userType = sessionStorage.getItem("userdata");
    let userToken = sessionStorage.getItem("userToken");

    const prepareFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            const unix = Math.round(+new Date() / 1000);
            const year = currentYear.getYear() + 1900;

            const fileArr = file.name.split(".");
            const ext = fileArr[fileArr.length - 1];
            let fileName =
                projectName +
                "/" +
                userType +
                "/" +
                userType +
                "-" +
                userToken +
                "/" +
                year +
                "/" +
                fileType +
                "_" +
                unix +
                "." +
                ext;
            onChange(file, fileName);
        }
    };

    return (
        <span>
            <TextField
                size="small"
                margin="normal"
                required
                fullWidth
                label={label}
                type="file"
                InputLabelProps={{ shrink: true }}
                sx={sx}
                onChange={(e) => prepareFile(e)}
            />
            <FormHelperText></FormHelperText>
        </span>
    );
};

export default FileUploadComponent;
