import * as React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SnackbarComponent from "../common/SnackbarComponent";
import { DataGrid } from "@mui/x-data-grid";

const axios = require("axios");

export default function VaccinationReview() {
    const [message, setMessage] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [vaccineData, setVaccineData] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false);
        setMessage("");
    };

    React.useEffect(async () => {
        await fetchVaccinationData();
    }, []);

    React.useEffect(() => {
        prepareRows(vaccineData);
    }, ["vaccineData"]);

    const fetchVaccinationData = async () => {
        const response = await axios.get(
            process.env.REACT_APP_API_URL +
                "/vax-tracker/fetch-vaccination-data/"
        );
        const { data } = response.data;
        setVaccineData(data);
    };

    const prepareRows = (data) => {
        const tmpRow = [];
        data.map((row, i) => {
            tmpRow.push({
                id: 1,
                submit_date: "04/12/2021",
                first_name: "Test name",
                last_name: "Test name",
                review_type: "Vaccination",
            });
        });
        setRows(tmpRow);
    };

    const columns = [
        {
            field: "submit_date",
            headerName: "Submit Date",
            width: 210,
        },
        {
            field: "first_name",
            headerName: "First Name",
            width: 215,
        },
        {
            field: "last_name",
            headerName: "Last Name",
            width: 215,
        },
        {
            field: "review_type",
            headerName: "Review Type",
            width: 210,
        },
        {
            field: "action",
            headerName: "Actions",
            width: 300,
            renderCell: (params) => (
                <Button
                    onClick={() => handleOpen()}
                    variant="contained"
                    sx={{ mr: "5px" }}
                    size="small"
                >
                    Details
                </Button>
            ),
        },
    ];

    return (
        <Container component="main" maxWidth="lg">
            <SnackbarComponent
                message={message}
                openSnackBar={openSnackBar}
                closeSnackBar={handleCloseSnackBar}
                propsVariant={"outlined"}
                severity={severity}
            />
            <div>
                <div>
                    <img
                        className="header-logo"
                        src={process.env.REACT_APP_LOGO}
                        alt="Logo"
                    />
                </div>
                <Typography component="h1" variant="h5">
                    COVID-19 Compliance Review
                </Typography>
                <Typography component="p" variant="p">
                    Vaccination
                </Typography>
                <br />
                <Container disableGutters sx={{ width: "100%" }}>
                    <DataGrid
                        rows={rows}
                        autoHeight
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        // checkboxSelection
                        disableSelectionOnClick
                        disableColumnMenu
                    />
                </Container>
            </div>
        </Container>
    );
}
