import Box from "@mui/material/Box";
import LinearProgressComponent from "../../common/LinearProgressComponent";
import LoadingButton from "@mui/lab/LoadingButton";

const StepperFooterButton = ({
    loading,
    repSubscription,
    handleClose,
    fileValidation,
    activeStep,
    handleBack,
    steps,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                pt: 2,
            }}
        >
            {loading ? (
                <LinearProgressComponent />
            ) : repSubscription ? (
                <>
                    <LoadingButton
                        variant="contained"
                        color="inherit"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={() => handleClose}
                    >
                        Cancel
                    </LoadingButton>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        size="small"
                        onClick={() => {
                            fileValidation();
                        }}
                    >
                        Submit
                    </LoadingButton>
                </>
            ) : (
                <>
                    <LoadingButton
                        variant="contained"
                        color="inherit"
                        size="small"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </LoadingButton>
                    <Box sx={{ flex: "1 1 auto" }} />

                    {activeStep === steps.length - 1 ? (
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            size="small"
                        >
                            Pay Subscription
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            type="submit"
                            loading={loading}
                            variant="contained"
                            size="small"
                            onClick={() => {
                                fileValidation;
                            }}
                        >
                            Next
                        </LoadingButton>
                    )}
                </>
            )}
        </Box>
    );
};

export default StepperFooterButton;
