import * as React from "react";
import { Switch } from "react-router-dom";
import { SignIn, SignUp, ForgotPassword, AdminSignIn } from "./AuthPages";
import {
    Dashboard,
    DashboardREP,
    DashboardAdmin,
    VaccinationReview,
    Profile,
    OrgProfile,
    ChangePassword,
    PaymentMethods,
    Subscription,
    RepSubscription,
    VaxTracker,
    RepProfile,
    VaccineStatus,
    Invoice,
} from "./Pages";
import Header from "./theme/Header";
import HeaderRep from "./theme/HeaderRep";
import HeaderAdmin from "./theme/HeaderAdmin";
import Footer from "./theme/Footer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { _forcedItems } from "./functions";

const theme = createTheme({
    palette: {
        primary: {
            main: "#87C643",
            dark: "#37a000",
            contrastText: "#fff",
        },
        secondary: {
            main: "#37a000",
        },
        error: {
            main: "#f44336",
        },
        textLink: {
            main: "#000",
        },
        success: {
            main: "#87C643",
        },
    },
    typography: {
        validationError: {
            fontWeight: "medium",
            fontSize: "12",
        },
        textLink: {
            fontWeight: "400",
            fontSize: "1rem",
            lineHeight: "1.5",
            letterSpacing: "0.00938em",
        },
    },
    direction: "ltr",
});

const requireLogin = (to, from, next) => {
    if (to.meta.auth) {
        let userId = sessionStorage.getItem("userId");
        if (userId == null || userId == "") {
            let userType = sessionStorage.getItem("userType");
            if (userType != null && userType != "") {
                if (userType == "ORG" || userType == "REP") {
                    next.redirect("/login");
                } else {
                    next.redirect("/admin-login");
                }
            } else {
                next.redirect("/login");
            }
        } else {
            // const response = _forcedItems(next);
            next();
        }
    } else {
        let userId = sessionStorage.getItem("userId");
        if (userId == null || userId == "") {
            next();
        } else {
            let userType = sessionStorage.getItem("userType");

            if (userType == "ORG") {
                next.redirect("/dashboard");
            } else if (userType == "REP") {
                next.redirect("/dashboard-rep");
            } else {
                next.redirect("/dashboard-admin");
            }
        }
    }
};
function NavHeader() {
    let userType = sessionStorage.getItem("userType");

    if (userType != null && userType != "") {
        if (userType == "ORG") {
            return <Header />;
        } else if (userType == "REP") {
            return <HeaderRep />;
        } else {
            return <HeaderAdmin />;
        }
    } else {
        return null;
    }
}

function NavFooter() {
    let userId = sessionStorage.getItem("userId");
    if (userId != null && userId != "") {
        return <Footer />;
    } else {
        return null;
    }
}

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {(sessionStorage.getItem("userId") != null ||
                sessionStorage.getItem("userId") != "") && <NavHeader />}
            <BrowserRouter>
                <GuardProvider guards={[requireLogin]}>
                    <Switch>
                        <GuardedRoute exact path="/" component={SignIn} />
                        <GuardedRoute
                            exact
                            path="/sign-up"
                            component={SignUp}
                        />
                        <GuardedRoute exact path="/login" component={SignIn} />
                        <GuardedRoute
                            exact
                            path="/admin-login"
                            component={AdminSignIn}
                        />
                        <GuardedRoute
                            exact
                            path="/vaccine-status/:token"
                            component={VaccineStatus}
                        />
                        <GuardedRoute
                            exact
                            path="/forgot-password"
                            component={ForgotPassword}
                        />
                        <GuardedRoute
                            exact
                            path="/dashboard"
                            component={Dashboard}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/dashboard-rep"
                            component={DashboardREP}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/dashboard-admin"
                            component={DashboardAdmin}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/vaccination-review"
                            component={VaccinationReview}
                            meta={{ auth: true }}
                        />

                        <GuardedRoute
                            exact
                            path="/profile"
                            component={Profile}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/org-profile"
                            component={OrgProfile}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/rep-profile"
                            component={RepProfile}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/change-password"
                            component={ChangePassword}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/payment-methods"
                            component={PaymentMethods}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/invoice"
                            component={Invoice}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/subscription"
                            component={Subscription}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/rep-subscription"
                            component={RepSubscription}
                            meta={{ auth: true }}
                        />
                        <GuardedRoute
                            exact
                            path="/vax-tracker"
                            component={VaxTracker}
                            meta={{ auth: true }}
                        />
                    </Switch>
                </GuardProvider>
            </BrowserRouter>
            {(sessionStorage.getItem("userId") != null ||
                sessionStorage.getItem("userId") != "") && <NavFooter />}
        </ThemeProvider>
    );
}
