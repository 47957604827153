import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Table from "@mui/material/Table";
import { DataGrid } from "@mui/x-data-grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarComponent from "../common/SnackbarComponent";
import Fade from "@mui/material/Fade";

const axios = require("axios");

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "7px",
    bgcolor: "background.paper",
    border: "1px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

export default function PaymentMethods() {
    const [card, setCard] = React.useState();
    const [cvv, setCvv] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [loaderType, setLoaderType] = React.useState("");
    const [loaderIndex, setLoaderIndex] = React.useState("");
    const [menu, setMenu] = React.useState(null);
    const [menuIndex, setMenuIndex] = React.useState(null);
    const [paymentMethods, setPaymentMethods] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const menuOpen = Boolean(menu);
    const months = [
        "01 - Jan",
        "02 - Feb",
        "03 - Mar",
        "04 - Apr",
        "05 - May",
        "06 - Jun",
        "07 - Jul",
        "08 - Aug",
        "09 - Sep",
        "10 - Oct",
        "11 - Nov",
        "12 - Dec",
    ];

    const currentYear = new Date().getFullYear();
    const years = [];
    for (var i = 0; i < 10; i++) {
        let year = currentYear + i;
        years.push(year.toString());
    }
    const currentMonth = new Date().getMonth() + 1;

    const handleMenuOpen = (event, id) => {
        setMenuIndex(id);
        setMenu(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenu(null);
    };

    const yupRules = {
        card: yup
            .number()
            .typeError("Card must be number")
            .required("Card is required")
            .test(
                "len",
                "Must be exactly 16 digits",
                (val) => val.toString().length === 16
            ),
        month: yup.string().required("Month is required").oneOf(months),
        year: yup.string().required("Year is required").oneOf(years),
        cvv: yup
            .string()
            .typeError("CVV must be number")
            .required("CVV is required")
            .matches(/[^a-zA-Z]/g, "Only numbers are allowed for this field ")
            .min(3, "Min 3 digits are required")
            .max(4, "Max 4 digits are required"),
        name: yup.string().required("Card Holder is required"),
    };

    const schemaValidation = yup.object().shape(yupRules);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaValidation),
    });

    const [message, setMessage] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false);
        setMessage("");
        setSeverity("success");
    };

    React.useEffect(() => {
        fetchPaymentMethods();
    }, []);

    const fetchPaymentMethods = () => {
        let userId = sessionStorage.getItem("userId");
        axios
            .get(process.env.REACT_APP_API_URL + "/payment-methods/" + userId)
            .then((response) => {
                setPaymentMethods(response.data.data);
                prepareRows(response.data.data);
            })
            .catch((error) => {
                console.log("There was an error!", error);
            });
    };

    const prepareRows = (data) => {
        const tmpRow = [];
        data.map((row, i) => {
            tmpRow.push({
                id: i + 1,
                card_holder: row.paymentMethodDetails.billing_details.name,
                card: row.paymentMethodDetails.card.last4,
                default: row.paymentMethod.default,
                paymentMethodId: row.paymentMethod.paymentMethodId,
                index: i,
                expiry_date:
                    row.paymentMethodDetails.card.exp_month < 10
                        ? "0" +
                          row.paymentMethodDetails.card.exp_month +
                          "/" +
                          row.paymentMethodDetails.card.exp_year
                        : row.paymentMethodDetails.card.exp_month +
                          "/" +
                          row.paymentMethodDetails.card.exp_year,
                expired: row.expired,
            });
        });
        setRows(tmpRow);
        handleClose();
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const setLoader = (type, index) => {
        setLoaderType(type);
        setLoaderIndex(index);
    };

    const clearLoader = () => {
        setLoaderType("");
        setLoaderIndex("");
    };

    const onSubmit = (data) => {
        setLoading(true);
        let userId = sessionStorage.getItem("userId");
        let { name, month, year } = data;
        month = month.split(" - ")[0];
        // call create payment method API
        axios
            .post(process.env.REACT_APP_API_URL + "/payment-methods/create", {
                name,
                card,
                month,
                year,
                cvv,
                userId,
            })
            .then((response) => {
                setLoading(false);
                if (response.data.status) {
                    setMessage("Card added");
                    setOpenSnackBar(true);
                    reset();
                    fetchPaymentMethods();
                } else {
                    setSeverity("error");
                    setMessage(response.data.message);
                    setOpenSnackBar(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setSeverity("error");
                setMessage("Something went wrong, try again");
                setOpenSnackBar(true);
            });
    };

    // function to handle delete event
    const deletePaymentMethod = (i, paymentMethodId) => {
        let userId = sessionStorage.getItem("userId");

        // call remove payment method API
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    "/payment-methods/remove/" +
                    userId +
                    "/" +
                    paymentMethodId
            )
            .then((response) => {
                if (response.data.status) {
                    // handleClose();
                    clearLoader();
                    setPaymentMethods(
                        paymentMethods.filter((p, index) => index !== i)
                    );
                    prepareRows(
                        paymentMethods.filter((p, index) => index !== i)
                    );
                    handleMenuClose();
                } else {
                    setMessage(response.data.message);
                }
            })
            .catch((error) => {
                setMessage(response.data.message);
            });
    };

    // function to mark payment method as default
    const defaultPaymentMethod = (i, paymentMethodId) => {
        let userId = sessionStorage.getItem("userId");

        // call remove payment method API
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    "/payment-methods/default/" +
                    userId +
                    "/" +
                    paymentMethodId
            )
            .then((response) => {
                if (response.data.status) {
                    setMessage("Default credit card updated");
                    setOpenSnackBar(true);
                    fetchPaymentMethods();
                    handleMenuClose();
                } else {
                    setSeverity("error");
                    setMessage(response.data.message);
                    setOpenSnackBar(true);
                }
            })
            .catch((error) => {
                setSeverity("error");
                setMessage("Something went wrong, try again");
                setOpenSnackBar(true);
            });
    };

    const columns = [
        {
            field: "card_holder",
            headerName: "Card Holder",
            sortable: false,
            width: 180,
        },
        {
            field: "card",
            headerName: "Card (Last 4 Digits)",
            sortable: false,
            width: 180,
        },
        {
            field: "expiry_date",
            headerName: "Expiry Date",
            sortable: false,
            width: 180,
            renderCell: (params) => (
                <>
                    {params.row.expired ? (
                        <Typography
                            component="span"
                            variant="span"
                            color="error"
                            sx={{
                                fontWeight: "bold",
                            }}
                        >
                            {params.row.expiry_date} expired
                        </Typography>
                    ) : (
                        params.row.expiry_date
                    )}
                </>
            ),
        },
        {
            field: "default",
            headerName: "Default",
            sortable: false,
            width: 180,
            renderCell: (params) => (
                <>
                    {params.row.default ? (
                        <Typography
                            component="span"
                            variant="span"
                            color="primary"
                            sx={{
                                fontWeight: "bold",
                            }}
                        >
                            Default
                        </Typography>
                    ) : (
                        ""
                    )}
                </>
            ),
        },
        {
            field: "action",
            headerName: "Actions",
            sortable: false,
            width: 100,
            renderCell: (params) => (
                <>
                    {!params.row.default && (
                        <MoreHorizIcon
                            id="positioned-button"
                            aria-controls="positioned-menu"
                            aria-haspopup="true"
                            aria-expanded={
                                menuOpen && menuIndex == params.id
                                    ? "true"
                                    : undefined
                            }
                            sx={{
                                "&:hover": {
                                    backgroundColor: "secondary.main",
                                    color: "secondary.contrastText",
                                },
                                backgroundColor: "gray.main",
                                borderRadius: "15px",
                                cursor: "pointer",
                            }}
                            onClick={(e) => handleMenuOpen(e, params.id)}
                        />
                    )}
                    <Menu
                        id="positioned-menu"
                        aria-labelledby="positioned-button"
                        anchorEl={menu}
                        open={menuOpen && menuIndex == params.id}
                        onClose={() => handleMenuClose()}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        {!params.row.expired && (
                            <MenuItem
                                onClick={() => {
                                    defaultPaymentMethod(
                                        params.row.index,
                                        params.row.paymentMethodId
                                    );
                                    setLoader("default", params.row.index);
                                }}
                            >
                                Make Default
                            </MenuItem>
                        )}

                        <MenuItem
                            onClick={() => {
                                deletePaymentMethod(
                                    params.row.index,
                                    params.row.paymentMethodId
                                );
                                setLoader("delete", params.row.index);
                            }}
                        >
                            Delete
                        </MenuItem>
                    </Menu>
                </>
            ),
        },
    ];

    return (
        <main>
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="md">
                    <SnackbarComponent
                        message={message}
                        openSnackBar={openSnackBar}
                        closeSnackBar={handleCloseSnackBar}
                        propsVariant={"outlined"}
                        severity={severity}
                    />
                    <Typography component="h1" variant="h3">
                        Payment Methods
                        <Button
                            onClick={() => handleOpen()}
                            sx={{ float: "right" }}
                            variant="contained"
                            size="small"
                        >
                            + Add New
                        </Button>
                    </Typography>
                    <Container disableGutters sx={{ width: "100%" }}>
                        <DataGrid
                            rows={rows}
                            autoHeight
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            // checkboxSelection
                            disableSelectionOnClick
                            disableColumnMenu
                        />
                    </Container>
                    {/* <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">
                                        Card Holder
                                    </TableCell>
                                    <TableCell align="center">
                                        Card (Last 4 Digits)
                                    </TableCell>
                                    <TableCell align="center">
                                        Expiry Date
                                    </TableCell>
                                    <TableCell align="center">
                                        Default
                                    </TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paymentMethods.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            "&:last-child td, &:last-child th":
                                                { border: 0 },
                                        }}
                                    >
                                        <TableCell align="center">
                                            {
                                                row.paymentMethodDetails
                                                    .billing_details.name
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                row.paymentMethodDetails.card
                                                    .last4
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.paymentMethodDetails.card
                                                .exp_month < 10
                                                ? "0" +
                                                  row.paymentMethodDetails.card
                                                      .exp_month
                                                : row.paymentMethodDetails.card
                                                      .exp_month}
                                            /
                                            {
                                                row.paymentMethodDetails.card
                                                    .exp_year
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {row.paymentMethod.default ? (
                                                "Default"
                                            ) : (
                                                <LoadingButton
                                                    loading={
                                                        loaderType ==
                                                            "default" &&
                                                        loaderIndex == index
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {
                                                        defaultPaymentMethod(
                                                            index,
                                                            row.paymentMethod
                                                                .paymentMethodId
                                                        );
                                                        setLoader(
                                                            "default",
                                                            index
                                                        );
                                                    }}
                                                    color="primary"
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    Make Default
                                                </LoadingButton>
                                            )}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            onClick={() => {
                                                deletePaymentMethod(
                                                    index,
                                                    row.paymentMethod
                                                        .paymentMethodId
                                                );
                                                setLoader("delete", index);
                                            }}
                                        >
                                            {row.paymentMethod.default ? (
                                                "Delete on Default not permitted"
                                            ) : (
                                                <LoadingButton
                                                    loading={
                                                        loaderType ==
                                                            "delete" &&
                                                        loaderIndex == index
                                                            ? true
                                                            : false
                                                    }
                                                    color="warning"
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    Delete
                                                </LoadingButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer> */}
                </Container>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Fade in={open}>
                    <Box sx={{ ...style }}>
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                size="small"
                                margin="normal"
                                required
                                fullWidth
                                name="name"
                                label="Card Holder"
                                type="text"
                                id="name"
                                sx={{ mt: "5px", mb: "0px" }}
                                {...register("name", {
                                    required: true,
                                })}
                            />
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                    typography: "validationError",
                                }}
                            >
                                {errors.name && errors.name.message}
                            </FormHelperText>
                            <TextField
                                size="small"
                                margin="normal"
                                required
                                fullWidth
                                name="card"
                                value={card || ""}
                                label="Card Number"
                                type="text"
                                id="card"
                                inputProps={{
                                    maxLength: 16,
                                    minLength: 16,
                                }}
                                sx={{ mt: "5px", mb: "0px", width: "180px" }}
                                {...register("card", {
                                    required: true,
                                    onChange: (e) => {
                                        var regexp = /[^0-9]/g;
                                        setCard(
                                            e.target.value.replace(regexp, "")
                                        );
                                    },
                                })}
                            />
                            <FormHelperText
                                sx={{
                                    color: "error.main",
                                    typography: "validationError",
                                }}
                            >
                                {errors.card && errors.card.message}
                            </FormHelperText>
                            <FormControl
                                sx={{ m: 0, width: "160px", mr: "7px" }}
                            >
                                <Select
                                    size="small"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="month"
                                    label="Expiry Month"
                                    id="month"
                                    displayEmpty
                                    sx={{ mt: "5px", mb: "0px" }}
                                    {...register("month", {
                                        required: true,
                                    })}
                                >
                                    <MenuItem>Select Month *</MenuItem>
                                    {months.map((m, i) => {
                                        return (
                                            <MenuItem key={i} value={m}>
                                                {m}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText
                                    sx={{
                                        color: "error.main",
                                        typography: "validationError",
                                        ml: "0px",
                                    }}
                                >
                                    {errors.month && errors.month.message}
                                </FormHelperText>
                            </FormControl>
                            <FormControl
                                sx={{ m: 0, width: "150px", mr: "7px" }}
                            >
                                <Select
                                    size="small"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="year"
                                    label="Expiry Year"
                                    id="year"
                                    displayEmpty
                                    sx={{ mt: "5px", mb: "0px" }}
                                    {...register("year", {
                                        required: true,
                                    })}
                                >
                                    <MenuItem>Select Year *</MenuItem>
                                    {years.map((m, i) => {
                                        return (
                                            <MenuItem key={i} value={m}>
                                                {m}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <FormHelperText
                                    sx={{
                                        color: "error.main",
                                        typography: "validationError",
                                        ml: "0px",
                                    }}
                                >
                                    {errors.year && errors.year.message}
                                </FormHelperText>
                            </FormControl>
                            <FormControl sx={{ m: 0, width: "150px" }}>
                                <TextField
                                    size="small"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="cvv"
                                    value={cvv || ""}
                                    label="CVV"
                                    type="text"
                                    id="cvv"
                                    inputProps={{ maxLength: 4, minLength: 3 }}
                                    sx={{ mt: "5px", mb: "0px", width: "70px" }}
                                    {...register("cvv", {
                                        required: true,
                                        onChange: (e) => {
                                            var regexp = /[^0-9]/g;
                                            setCvv(
                                                e.target.value.replace(
                                                    regexp,
                                                    ""
                                                )
                                            );
                                        },
                                    })}
                                />
                                <FormHelperText
                                    sx={{
                                        color: "error.main",
                                        typography: "validationError",
                                        ml: "0px",
                                    }}
                                >
                                    {errors.cvv && errors.cvv.message}
                                </FormHelperText>
                            </FormControl>
                            <br />
                            <Grid container>
                                <Grid item xs>
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        variant="contained"
                                        size="small"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Save
                                    </LoadingButton>
                                </Grid>
                                <Grid item>
                                    <LoadingButton
                                        variant="contained"
                                        color="inherit"
                                        size="small"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={() => {
                                            reset();
                                            handleClose();
                                        }}
                                    >
                                        Cancel
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Fade>
            </Modal>
        </main>
    );
}
