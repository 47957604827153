import * as React from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import SnackbarComponent from "../common/SnackbarComponent";
import DetailRow from "../components/Invoice/CustomDetailRow";
import { globalStyles } from "../functions";

const axios = require("axios");

export default function Invoices() {
    const [rows, setRows] = React.useState([]);
    const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
    const [details, setDetails] = React.useState({});
    const [invoices, setInvoices] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [menu, setMenu] = React.useState(null);
    const [menuIndex, setMenuIndex] = React.useState(null);
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const menuOpen = Boolean(menu);

    const handleClose = () => {
        setOpenDetailsModal(false);
    };
    const handleMenuOpen = (event, id) => {
        setMenuIndex(id);
        setMenu(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenu(null);
    };

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false);
        setMessage("");
        setSeverity("success");
    };

    React.useEffect(() => {
        fetchInvoices();
    }, []);

    const openDetails = async (details) => {
        setDetails(details.details);
        setOpenDetailsModal(true);
    };

    const fetchInvoices = () => {
        let userId = sessionStorage.getItem("userId");
        axios
            .get(process.env.REACT_APP_API_URL + "/invoices/" + userId)
            .then((response) => {
                setInvoices(response.data.data);

                const tmpRow = [];
                response.data.data.map((row, i) => {
                    tmpRow.push({
                        id: i + 1,
                        invoice_date: row.invoiceDate,
                        invoice_number: row.invoiceNumber,
                        transaction_id: row.invoiceDetails.subscriptionId,
                        card_holder: row.cardHolder,
                        card_number: row.last4,
                        product: row.invoiceDetails.description,
                        card_processin_fee: row.invoiceDetails.ccProcessingFees,
                        transaction_fee: row.invoiceDetails.amount,
                        sales_tax: row.invoiceDetails.salesTax,
                        total: row.invoiceDetails.total,
                        details: row,
                        status:
                            row.invoiceStatus == "paid"
                                ? "Paid"
                                : row.invoiceStatus == "paymentFailed"
                                ? "Payment Failed"
                                : "Error",
                    });
                });
                setRows(tmpRow);
            })
            .catch((error) => {
                console.log("There was an error!", error);
            });
    };

    const columns = [
        {
            field: "invoice_date",
            headerName: "Date",
            width: 100,
        },
        {
            field: "invoice_number",
            headerName: "Invoice #",
            width: 100,
        },
        {
            field: "card_holder",
            headerName: "Card Holder",
            width: 150,
        },
        {
            field: "card_number",
            headerName: "CC #",
            width: 100,
        },
        {
            field: "transaction_id",
            headerName: "Transaction ID",
            width: 250,
        },
        {
            field: "product",
            headerName: "Product",
            width: 150,
        },
        {
            field: "total",
            headerName: "Total",
            width: 100,
        },
        {
            field: "status",
            headerName: "Status",
            width: 100,
        },
        {
            field: "actions",
            headerName: "Actions",
            width: 100,
            renderCell: (params) => (
                <>
                    <MoreHorizIcon
                        id="positioned-button"
                        aria-controls="positioned-menu"
                        aria-haspopup="true"
                        aria-expanded={
                            menuOpen && menuIndex == params.id
                                ? "true"
                                : undefined
                        }
                        sx={{
                            "&:hover": {
                                backgroundColor: "secondary.main",
                                color: "secondary.contrastText",
                            },
                            backgroundColor: "gray.main",
                            borderRadius: "15px",
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleMenuOpen(e, params.id)}
                    />
                    <Menu
                        id="positioned-menu"
                        aria-labelledby="positioned-button"
                        anchorEl={menu}
                        open={menuOpen && menuIndex == params.id}
                        onClose={() => handleMenuClose()}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem
                            onClick={() =>
                                imageModal(params.row.vax_card, params)
                            }
                        >
                            Print
                        </MenuItem>
                        {params.row.invoiceStatus == "paid" && (
                            <MenuItem
                                onClick={() =>
                                    imageModal(params.row.vax_certification)
                                }
                            >
                                Retry Payment
                            </MenuItem>
                        )}
                        <MenuItem onClick={() => openDetails(params.row)}>
                            Details
                        </MenuItem>
                    </Menu>
                </>
            ),
        },
    ];

    return (
        <main>
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="lg">
                    <SnackbarComponent
                        message={message}
                        openSnackBar={openSnackBar}
                        closeSnackBar={handleCloseSnackBar}
                        propsVariant={"outlined"}
                        severity={severity}
                    />
                    <Typography component="h1" variant="h3">
                        Invoices
                    </Typography>
                    <Container disableGutters sx={{ width: "100%" }}>
                        <DataGrid
                            rows={rows}
                            autoHeight
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            // checkboxSelection
                            disableSelectionOnClick
                            disableColumnMenu
                        />
                    </Container>
                    <Modal
                        open={openDetailsModal}
                        onClose={handleClose}
                        aria-labelledby="child-modal-title"
                        aria-describedby="child-modal-description"
                    >
                        <Box
                            sx={{
                                ...globalStyles.modal,
                                top: "50% !important",
                            }}
                        >
                            <TableContainer>
                                <Table
                                    sx={{ minWidth: 400 }}
                                    aria-label="simple table"
                                >
                                    <TableBody>
                                        <DetailRow
                                            title={"Date"}
                                            value={details.invoiceDate}
                                        />
                                        <DetailRow
                                            title={"Invoice #"}
                                            value={details.invoiceNumber}
                                        />
                                        <DetailRow
                                            title={"Card Holder"}
                                            value={details.cardHolder}
                                        />
                                        <DetailRow
                                            title={"CC#"}
                                            value={details.last4}
                                        />
                                        <DetailRow
                                            title={"Transaction ID"}
                                            value={
                                                details.invoiceDetails &&
                                                details.invoiceDetails
                                                    .subscriptionId
                                            }
                                        />
                                        <DetailRow
                                            title={"Product"}
                                            value={
                                                details.invoiceDetails &&
                                                details.invoiceDetails
                                                    .description
                                            }
                                        />
                                        <DetailRow
                                            title={"Fee"}
                                            value={
                                                details.invoiceDetails &&
                                                details.invoiceDetails.amount
                                            }
                                        />
                                        <DetailRow
                                            title={"CC Processing Fee"}
                                            value={
                                                details.invoiceDetails &&
                                                details.invoiceDetails
                                                    .ccProcessingFees
                                            }
                                        />
                                        <DetailRow
                                            title={"Sales Tax"}
                                            value={
                                                details.invoiceDetails &&
                                                details.invoiceDetails.salesTax
                                            }
                                        />
                                        <DetailRow
                                            title={"Total"}
                                            value={
                                                details.invoiceDetails &&
                                                details.invoiceDetails.total
                                            }
                                        />
                                        <DetailRow
                                            title={"Status"}
                                            value={
                                                details.invoiceStatus == "paid"
                                                    ? "Paid"
                                                    : details.invoiceStatus ==
                                                      "paymentFailed"
                                                    ? "Payment Failed"
                                                    : "Error"
                                            }
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    pt: 2,
                                }}
                            >
                                <LoadingButton
                                    variant="contained"
                                    color="inherit"
                                    size="small"
                                    sx={{ mr: 1 }}
                                    onClick={() => handleClose()}
                                >
                                    Close
                                </LoadingButton>
                                <Box sx={{ flex: "1 1 auto" }} />
                            </Box>
                        </Box>
                    </Modal>
                </Container>
            </Box>
        </main>
    );
}
