import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const StepperSubscriptionWall = ({ plan_data }) => {
    return (
        <>
            <Container sx={{ width: "500px" }}>
                <Typography
                    component="p"
                    variant="h4"
                    sx={{
                        mt: "3px",
                        mb: "3px",
                        textAlign: "center",
                    }}
                >
                    Subscription
                </Typography>
                <Grid container>
                    <Grid item xs>
                        Annuaul Subscription Fee
                    </Grid>
                    <Grid item>${plan_data && plan_data.plan_data.price}</Grid>
                </Grid>
                <Grid container>
                    <Grid item xs>
                        Sales Text ({plan_data.sales_tax_per} %)
                    </Grid>
                    <Grid item>${plan_data.sales_tax_val}</Grid>
                </Grid>
                <Grid container>
                    <Grid item xs>
                        CC Processing Fee
                    </Grid>
                    <Grid item>${plan_data.processing_fee}</Grid>
                </Grid>
                <hr />
                <Grid container>
                    <Grid item xs>
                        Total Due
                    </Grid>
                    <Grid item>
                        ${plan_data && parseFloat(plan_data.plan_data.total)}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default StepperSubscriptionWall;
