import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { FormHelperText } from '@mui/material'
import SnackbarComponent from "../common/SnackbarComponent"
import SkeletonComponent from "../common/SkeletonComponent"
import LinearProgressComponent from "../common/LinearProgressComponent"
const axios = require("axios")

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {" © "}
            <Link color="inherit" href={process.env.REACT_APP_SITE_LINK}  target="_blank">
                {" "}
                {new Date().getFullYear()}
                Ally Solutions,LLC
            </Link>
        </Typography>
    )
}



const AdminSignIn = () => {
    const yupRules = {
        username: yup
            .string()
            .required("Email is required")
            .email("Please enter valid Email"),
        password: yup.string().required("Password is required"),
    }

    const schemaValidation = yup.object().shape(yupRules)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaValidation),
    })
    const [loading, setLoad] = React.useState(false)
    const [formLoading, setFormLoading] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [openSnackBar, setOpenSnackBar] = React.useState(false)
    const [severity, setSeverity] = React.useState("success")

    React.useEffect(() => {        
        setLoad(false)
      },[]);

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false)
        setMessage("")
    }

    const onSubmit = async (data) => {
        setFormLoading(true)
        const { username, password } = data

        // call to auth0 to check user
        await axios
            .post(process.env.REACT_APP_API_URL + "/admin-login", {
                username,
                password,
            })
            .then((response) => {
                setFormLoading(false)
                if (response.data.status) {
                    let { data, access_token } = response.data
                    sessionStorage.setItem("userId", data._id)                    
                    sessionStorage.setItem("userType", data.userType)
                    //sessionStorage.setItem("forcedItems", JSON.stringify(data.forcedItems))
                    sessionStorage.setItem("access_token", access_token)
                    history.go(0)
                } else {
                    setSeverity("error")
                    setMessage(response.data.message)
                    setOpenSnackBar(true)
                }
            })
            .catch((error) => {
                setFormLoading(false)
                console.log(error)
                setSeverity("error")
                setMessage("Invalid credentials!!")
                setOpenSnackBar(true)
            })
    }
    

    return (
    
        <Container component="main" maxWidth="xs">
            <SnackbarComponent
                message={message}
                openSnackBar={openSnackBar}
                closeSnackBar={handleCloseSnackBar}
                severity={severity}                    
            />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
               
               {loading ? (
                    <SkeletonComponent type="circular" width={120} height={120} />
                ) : (
                    <Box>
                        <img
                            className="header-logo"
                            src={process.env.REACT_APP_LOGO}
                            alt="Logo"
                        />
                    </Box>
                )}
                <Typography component="h1" variant="h5">
                    {loading ? <SkeletonComponent type="text" width={350} height={50}  /> : "Admin Login"}
                </Typography>

                <Box sx={{ mt: 1,  width: "100%" }}>
                    {loading ? (
                        <Box sx={{  mb: 4 }}><SkeletonComponent type="rectangular" width={390} height={400} /></Box>
                    ) : (     
                        <form onSubmit={handleSubmit(onSubmit)}>   
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                id="username"
                                label="Email"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                error={!!errors.username}
                                {...register("username")}
                                
                            />
                            <FormHelperText sx={{ color: 'error.main',typography:'validationError' }}>{errors.username && errors.username.message}</FormHelperText>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                name="password"
                                label="Password"
                                type="password"
                                id="password"  
                                error={!!errors.password}                      
                                {...register("password")}
                            />
                            <FormHelperText sx={{ color: 'error.main',typography:'validationError' }}>{errors.password && errors.password.message}</FormHelperText>
                            {formLoading ? (
                                <LinearProgressComponent  />
                            ) : ( 
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Sign in
                                </Button>
                            )}
                        </form>
                    )}
                    <Grid container>
                    <Grid item xs>
                    {loading ? (
                        <SkeletonComponent type="text" width={120} height={20} />
                    ) : (  
                        <Link
                            href="/forgot-password"
                            variant="body2"
                            color="primary"
                        >
                            Forgot password?
                        </Link>
                    )}    
                    </Grid>
                    
                </Grid>
            </Box>
        </Box>
            <Copyright sx={{ mt: 8, mb: 4 }} />
            
        </Container>
    
    )
}

export default AdminSignIn
