import * as React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarComponent from "../common/SnackbarComponent";

const axios = require("axios");

export default function Profile(props) {
    // initiate state
    const [profile, setProfile] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [contacts, setContacts] = React.useState([
        {
            value: "",
            label: "",
            type: "phone",
        },
    ]);
    const [addresses, setAddresses] = React.useState([
        {
            label: "",
            address: "",
            address2: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
        },
    ]);

    const yupRules = {
        first_name: yup.string().required("First Name is required"),
        last_name: yup.string().required("Last Name is required"),
        gender: yup
            .string()
            .required("Gender is required")
            .oneOf(["male", "female"]),
        birthdate: yup.string().required("Birthday is required"),
    };

    const schemaValidation = yup.object().shape(yupRules);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaValidation),
    });

    const [message, setMessage] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false);
        setMessage("");
    };

    // fetch user profile
    React.useEffect(() => {
        let userId = sessionStorage.getItem("userId");
        axios
            .get(process.env.REACT_APP_API_URL + "/profile/" + userId)
            .then((response) => {
                setProfile(response.data.data);
                if (response.data.data.contacts) {
                    setContacts(response.data.data.contacts);
                }
                if (response.data.data.addresses) {
                    setAddresses(response.data.data.addresses);
                }
            })
            .catch((error) => {
                console.log("There was an error!", error);
            });
    }, []);

    // form submit will be handle via below function
    const onSubmit = (event) => {
        event.preventDefault();
        // const data = new FormData(event.currentTarget);
        let userId = sessionStorage.getItem("userId");

        // add contacts and addresses to profile
        let tmpProfile = profile;
        tmpProfile.contacts = contacts;
        tmpProfile.addresses = addresses;
        setProfile(tmpProfile);

        // call save profile API
        axios
            .post(process.env.REACT_APP_API_URL + "/profile/update", {
                profile,
                userId,
            })
            .then((response) => {
                if (response.data.status) {
                    setMessage("Profile saved");
                    setOpenSnackBar(true);
                    // props.history.push('/dashboard');
                } else {
                    setSeverity("error");
                    setMessage(response.data.message);
                    setOpenSnackBar(true);
                }
            })
            .catch((error) => {
                setSeverity("error");
                setMessage("Could not save details");
                setOpenSnackBar(true);
            });
    };

    // below functions to add/remove dynamic contact/address fields
    let addContact = () => {
        setContacts([...contacts, { value: "", label: "", type: "phone" }]);
    };
    let addAddress = () => {
        setAddresses([
            ...addresses,
            {
                label: "",
                address: "",
                address2: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
            },
        ]);
    };
    let remove = (type, index) => {
        if (type == "contact") {
            let tmpContacts = [...contacts];
            tmpContacts.splice(index, 1);
            setContacts(tmpContacts);
        } else if (type == "address") {
            let tmpAddresses = [...addresses];
            tmpAddresses.splice(index, 1);
            setAddresses(tmpAddresses);
        }
    };

    return (
        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <SnackbarComponent
                        message={message}
                        openSnackBar={openSnackBar}
                        closeSnackBar={handleCloseSnackBar}
                        propsVariant={"outlined"}
                        severity={severity}
                    />
                    <Typography component="h1" variant="h3">
                        My Profile
                    </Typography>
                    <Typography component="h5" variant="h5" sx={{ mt: "15px" }}>
                        Personal Details
                    </Typography>
                    <Box component="form" onSubmit={onSubmit}>
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            name="first_name"
                            label="First Name"
                            type="text"
                            value={profile.first_name || ""}
                            onChange={(e) =>
                                setProfile({
                                    ...profile,
                                    first_name: e.target.value,
                                })
                            }
                            id="first_name"
                            sx={{ mt: "0px" }}
                            error={profile.first_name ? false : true}
                        />
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            name="last_name"
                            label="Last Name"
                            type="text"
                            value={profile.last_name || ""}
                            onChange={(e) =>
                                setProfile({
                                    ...profile,
                                    last_name: e.target.value,
                                })
                            }
                            id="last_name"
                            sx={{ mt: "0px" }}
                            error={profile.last_name ? false : true}
                        />
                        <Select
                            size="small"
                            required
                            name="gender"
                            // label="Gender"
                            displayEmpty
                            value={profile.gender || ""}
                            onChange={(e) =>
                                setProfile({
                                    ...profile,
                                    gender: e.target.value,
                                })
                            }
                            id="gender"
                            sx={{ mt: "0px", mr: "10px" }}
                            error={profile.gender == "" ? true : false}
                        >
                            <MenuItem value="">Select Gender</MenuItem>
                            <MenuItem value={"male"}>Male</MenuItem>
                            <MenuItem value={"female"}>Female</MenuItem>
                        </Select>
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            name="birthdate"
                            label="Birthday"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={profile.birthdate || ""}
                            onChange={(e) =>
                                setProfile({
                                    ...profile,
                                    birthdate: e.target.value,
                                })
                            }
                            id="birthdate"
                            sx={{ mt: "0px" }}
                            error={profile.birthdate ? false : true}
                        />
                        <Typography
                            component="h5"
                            variant="h5"
                            sx={{ mt: "15px" }}
                        >
                            Contact Info
                        </Typography>
                        {contacts.map((contact, index) => {
                            return (
                                <span key={index}>
                                    <Typography
                                        component="p"
                                        variant="p"
                                        sx={{ mt: "0px" }}
                                    >
                                        Contact {index + 1}{" "}
                                        {index != 0 && (
                                            <Button
                                                onClick={(e) =>
                                                    remove("contact", index)
                                                }
                                                size="small"
                                                variant="text"
                                            >
                                                X
                                            </Button>
                                        )}
                                    </Typography>
                                    <Select
                                        size="small"
                                        required
                                        name="label"
                                        displayEmpty
                                        value={contact.label || ""}
                                        onChange={(e) => {
                                            let newContacts = [...contacts];
                                            newContacts[index].label =
                                                e.target.value;
                                            setContacts(newContacts);
                                        }}
                                        id="label"
                                        sx={{ mt: "0px", mb: "10px" }}
                                        error={contact.label ? false : true}
                                    >
                                        <MenuItem value="">
                                            Select Phone Type
                                        </MenuItem>
                                        <MenuItem value={"home"}>Home</MenuItem>
                                        <MenuItem value={"mobile"}>
                                            Mobile
                                        </MenuItem>
                                    </Select>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px" }}
                                        fullWidth
                                        name="value"
                                        label={"Contact Number " + (index + 1)}
                                        type="text"
                                        value={contact.value || ""}
                                        onChange={(e) => {
                                            let regexp = /[^0-9]/g;
                                            let newContacts = [...contacts];
                                            // if(e.target.value.match(regexp)){
                                            newContacts[index].value =
                                                e.target.value.replace(
                                                    regexp,
                                                    ""
                                                );
                                            setContacts(newContacts);
                                            // }
                                        }}
                                        id="value"
                                        error={contact.value ? false : true}
                                    />
                                </span>
                            );
                        })}
                        <Button
                            onClick={() => addContact()}
                            variant="contained"
                            size="small"
                            color="inherit"
                        >
                            + Add New
                        </Button>
                        <Typography
                            component="h5"
                            variant="h5"
                            sx={{ mt: "15px" }}
                        >
                            Home Address
                        </Typography>
                        {addresses.map((address, index) => {
                            return (
                                <span key={index}>
                                    <Typography
                                        component="p"
                                        variant="p"
                                        sx={{ mt: "0px" }}
                                    >
                                        Address {index + 1}{" "}
                                        {index != 0 && (
                                            <Button
                                                onClick={(e) =>
                                                    remove("address", index)
                                                }
                                                size="small"
                                                variant="text"
                                            >
                                                X
                                            </Button>
                                        )}
                                    </Typography>
                                    <Select
                                        size="small"
                                        required
                                        name="label"
                                        displayEmpty
                                        value={address.label || ""}
                                        onChange={(e) => {
                                            let newAddresses = [...addresses];
                                            newAddresses[index].label =
                                                e.target.value;
                                            setAddresses(newAddresses);
                                        }}
                                        id="label"
                                        sx={{ mt: "0px", mb: "10px" }}
                                        error={address.label ? false : true}
                                    >
                                        <MenuItem value="">
                                            Select Address Type
                                        </MenuItem>
                                        <MenuItem value={"home"}>Home</MenuItem>
                                        <MenuItem value={"office"}>
                                            Office
                                        </MenuItem>
                                    </Select>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px" }}
                                        name="address1"
                                        label="Address Line 1"
                                        fullWidth
                                        type="text"
                                        value={address.address1 || ""}
                                        onChange={(e) => {
                                            let newAddresses = [...addresses];
                                            newAddresses[index].address1 =
                                                e.target.value;
                                            setAddresses(newAddresses);
                                        }}
                                        id="address1"
                                        error={address.address1 ? false : true}
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: "0px" }}
                                        name="address2"
                                        label="Address Line 2"
                                        fullWidth
                                        type="text"
                                        value={address.address2 || ""}
                                        onChange={(e) => {
                                            let newAddresses = [...addresses];
                                            newAddresses[index].address2 =
                                                e.target.value;
                                            setAddresses(newAddresses);
                                        }}
                                        id="address2"
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: "0px" }}
                                        name="address3"
                                        label="Address Line 3"
                                        fullWidth
                                        type="text"
                                        value={address.address3 || ""}
                                        onChange={(e) => {
                                            let newAddresses = [...addresses];
                                            newAddresses[index].address3 =
                                                e.target.value;
                                            setAddresses(newAddresses);
                                        }}
                                        id="address3"
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px" }}
                                        name="city"
                                        label="City"
                                        fullWidth
                                        type="text"
                                        value={address.city || ""}
                                        onChange={(e) => {
                                            let regexp =
                                                /[0-9`~!@#$%^&*()_|+\-=?;:'".<>\{\}\[\]\\\/]/g;
                                            let newAddresses = [...addresses];
                                            if (e.target.value.match(regexp)) {
                                                newAddresses[index].city =
                                                    e.target.value
                                                        .replace(regexp, "")
                                                        .toUpperCase();
                                            } else {
                                                newAddresses[index].city =
                                                    e.target.value.toUpperCase();
                                            }
                                            setAddresses(newAddresses);
                                        }}
                                        id="city"
                                        error={address.city ? false : true}
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px", width: "33%" }}
                                        name="state"
                                        label="State"
                                        fullWidth
                                        type="text"
                                        value={address.state || ""}
                                        onChange={(e) => {
                                            let regexp = /[^a-zA-Z]/g;
                                            let newAddresses = [...addresses];
                                            if (e.target.value.match(regexp)) {
                                                newAddresses[index].state =
                                                    e.target.value
                                                        .replace(regexp, "")
                                                        .toUpperCase();
                                            } else {
                                                newAddresses[index].state =
                                                    e.target.value.toUpperCase();
                                            }
                                            setAddresses(newAddresses);
                                        }}
                                        inputProps={{
                                            maxLength: 2,
                                            minLength: 2,
                                        }}
                                        id="state"
                                        error={address.state ? false : true}
                                    />
                                    <br />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px", width: "33%" }}
                                        name="zipCode"
                                        label="Postal Code"
                                        fullWidth
                                        type="text"
                                        value={address.zipCode || ""}
                                        onChange={(e) => {
                                            let regexp = /[^0-9]/g;
                                            let newAddresses = [...addresses];
                                            // if(e.target.value.match(regexp)){
                                            newAddresses[index].zipCode =
                                                e.target.value.replace(
                                                    regexp,
                                                    ""
                                                );
                                            setAddresses(newAddresses);
                                            // }
                                        }}
                                        id="zipCode"
                                        error={address.zipCode ? false : true}
                                    />
                                    <br />
                                </span>
                            );
                        })}
                        <LoadingButton
                            type="submit"
                            fullWidth
                            loading={loading}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Container>
            </Box>
        </main>
    );
}
