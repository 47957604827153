import * as React from "react"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import ListItem from "@mui/material/ListItem"
import Link from "@mui/material/Link"

import ListItemText from "@mui/material/ListItemText"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { useTheme } from '@mui/styles'
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"

import IconButton from "@mui/material/IconButton"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline"
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import Avatar from "@mui/material/Avatar"
import Tooltip from '@mui/material/Tooltip'

const firstDrawerWidth = 40
const drawerWidth = 220


export default function Header() {
    const theme = useTheme()

    const drawerHeader= {
        display: "flex",
        alignItems: "center",
        p: (0, 1),
        mixins:"toolbar",
        justifyContent: "flex-end",
    }

    const drawerHeading= {
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "1rem",
    }

    

    //const history = useHistory()
    const onRedirect = (url) => {
        window.location.href = url
        //history.push(url)
        
    }

    const toggleDrawer = (anchor, open) => (event) => {
        
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const [state, setState] = React.useState({
        profileSubDrawer: false,
        profileComplainceSubDrawer: false,
        profileContactSubDrawer: false,
        repManagerDrawer: false,
        complainceDrawer: false,
        monitoringDrawer: false,
        orgManagementDrawer: false,
        reviewInsuranceSubDrawer: false,
    })

    return (
        <Box>
            <AppBar
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 , bgcolor: "background.paper"}}
            >
                <Toolbar variant="dense" sx={{minHeight: 40}}>
                    <Box>
                        <Link href="/dashboard-org">
                            <img
                                src={process.env.REACT_APP_LOGO}
                                alt="Logo"
                            />
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider variant="fullWidth" sx={{bgcolor:"common.black"}} />            
                <Box>
                    <Drawer
                        sx={{
                            width: firstDrawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                            width: firstDrawerWidth,
                            boxSizing: 'border-box',
                            marginTop: 5,
                            bgcolor: "primary.main",
                            paddingTop: 1.3,
                            },
                        }}
                        
                        variant="permanent"
                        anchor="left"
                    >
                        <Tooltip title="Account Management" placement="right" arrow>
                            <IconButton
                                onClick={toggleDrawer("profileSubDrawer", true)}
                            >
                                {" "}
                                <Avatar
                                    sx={{ bgcolor: "common.white", width: 30, height: 30 }}
                                >
                                    {" "}
                                    <PersonOutlineIcon color="primary" />
                                </Avatar>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="My Compliances" placement="right" arrow>
                            <IconButton
                                onClick={toggleDrawer("complainceDrawer", true)}
                            >
                                {" "}
                                <Avatar
                                    sx={{ bgcolor: "common.white", width: 30, height: 30 }}
                                >
                                    {" "}
                                    <PlaylistAddCheckIcon color="primary" />
                                </Avatar>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Rep Management" placement="right" arrow>
                            <IconButton
                                onClick={toggleDrawer("repManagerDrawer", true)}
                            >
                                {" "}
                                <Avatar
                                    sx={{ bgcolor: "common.white", width: 30, height: 30 }}
                                >
                                    {" "}
                                    <PeopleOutlineIcon color="primary" />
                                </Avatar>
                            </IconButton>
                        </Tooltip>                        
                        
                        <Tooltip title="Org Management" placement="right" arrow>
                            <IconButton
                                onClick={toggleDrawer("orgManagementDrawer", true)}
                            >
                                {" "}
                                <Avatar
                                    sx={{ bgcolor: "common.white", width: 30, height: 30 }}
                                >
                                    {" "}
                                    <SwapHorizIcon color="primary" />
                                </Avatar>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Review" placement="right" arrow>
                            <IconButton
                                onClick={toggleDrawer("monitoringDrawer", true)}
                            >
                                {" "}
                                <Avatar
                                    sx={{ bgcolor: "common.white", width: 30, height: 30 }}
                                >
                                    {" "}
                                    <FindInPageOutlinedIcon color="primary" />
                                </Avatar>
                            </IconButton>
                        </Tooltip>

                        {/* 1. Profile Drawer */}
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,                            
                                    marginTop: 5,
                                    },
                            }}
                            anchor="left"
                            open={state["profileSubDrawer"]}
                            onClose={toggleDrawer("profileSubDrawer", false)}
                            
                        >
                            <Box sx={drawerHeader}>
                                <Typography>
                                    <Box sx={drawerHeading}>
                                        Account Management
                                    </Box>
                                </Typography>
                                <IconButton
                                    onClick={toggleDrawer(
                                        "profileSubDrawer",
                                        false
                                    )}
                                >
                                    {theme.direction === "ltr" ? (
                                        <ChevronLeftIcon />
                                    ) : (
                                        <ChevronRightIcon />
                                    )}
                                </IconButton>
                            </Box>
                            <Divider />
                            <List> 

                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        onClick={() => onRedirect("/org-profile")}
                                        primary="Company Profile"
                                    /> 
                                </ListItem>

                                <ListItem button>
                                    <ListItemText 
                                        sx={{ color: 'textLink.main',typography:'textLink' }} 
                                        onClick={() => onRedirect("/profile")}
                                        primary="User Profile"
                                    /> 
                                </ListItem> 
                                
                                {/*<ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="SubUser"
                                        onClick={() => onRedirect("/sub-user")}
                                    />
                                </ListItem>
                                       
                                
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="User Role"
                                        onClick={() => onRedirect("/user-role")}
                                    />
                                </ListItem>
                                       
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Complaince Settings"
                                        onClick={toggleDrawer(
                                            "profileComplainceSubDrawer",
                                            true
                                        )}
                                    />{" "}
                                    <ChevronRightIcon />
                                </ListItem>*/}

                                <ListItem button>
                                    <ListItemText
                                    sx={{ color: 'textLink.main',typography:'textLink' }}
                                    onClick={() => onRedirect("/complaince-settings")}
                                    primary="Complaince Settings"
                                    />
                                </ListItem>
                                        
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Contract Manager "
                                        onClick={toggleDrawer(
                                            "profileContactSubDrawer",
                                            true
                                        )}
                                    />{" "}
                                    <ChevronRightIcon />
                                </ListItem>

                                
                            
                                <ListItem button>
                                    <ListItemText
                                    sx={{ color: 'textLink.main',typography:'textLink' }}
                                    onClick={() => onRedirect("/change-password")}
                                    primary="Change Password"
                                    />
                                </ListItem>
                            
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        onClick={() => onRedirect("/payment-methods")}
                                        primary="Payment Method"
                                    />
                                </ListItem>
                            
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Invoice"
                                        onClick={() => onRedirect("/invoice")}
                                    />
                                </ListItem>
                                
                            </List>

                            {/* 2.1 Profile Subsub Drawer */}
                            {/*<Drawer
                                sx={{
                                    width: drawerWidth,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,                            
                                        marginTop: 5,
                                        },
                                }}
                                anchor="left"
                                open={state["profileComplainceSubDrawer"]}
                                onClose={toggleDrawer(
                                    "profileComplainceSubDrawer",
                                    false
                                )}
                               
                            >
                                <Box sx={drawerHeader}>
                                    <Typography>
                                        <Box sx={drawerHeading}>
                                            Complaince Settings
                                        </Box>
                                    </Typography>
                                    <IconButton
                                        onClick={toggleDrawer(
                                            "profileComplainceSubDrawer",
                                            false
                                        )}
                                    >
                                        {theme.direction === "ltr" ? (
                                            <ChevronLeftIcon />
                                        ) : (
                                            <ChevronRightIcon />
                                        )}
                                    </IconButton>
                                </Box>
                                <Divider />
                                <List>                                       
                                    <ListItem button>
                                        <ListItemText
                                            sx={{ color: 'textLink.main',typography:'textLink' }}
                                            primary="Org Settings"
                                            onClick={() =>
                                                onRedirect("/org-settings")
                                            }
                                        />
                                    </ListItem>                                
                                    <ListItem button>
                                        <ListItemText
                                            sx={{ color: 'textLink.main',typography:'textLink' }}
                                            primary="Rep Settings"
                                            onClick={() =>
                                                onRedirect("/rep-settings")
                                            }
                                        />
                                    </ListItem>                                    
                                </List>
                            </Drawer>*/}

                            {/* 2.2 profile contract subsub Drawer */}
                            <Drawer
                                sx={{
                                    width: drawerWidth,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,                            
                                        marginTop: 5,
                                        },
                                }}
                                anchor="left"
                                open={state["profileContactSubDrawer"]}
                                onClose={toggleDrawer(
                                    "profileContactSubDrawer",
                                    false
                                )}
                                
                            >
                                <Box sx={drawerHeader}>
                                    <Typography>
                                        <Box sx={drawerHeading}>
                                            Contact Manager
                                        </Box>
                                    </Typography>
                                    <IconButton
                                        onClick={toggleDrawer(
                                            "profileContactSubDrawer",
                                            false
                                        )}
                                    >
                                        {theme.direction === "ltr" ? (
                                            <ChevronLeftIcon />
                                        ) : (
                                            <ChevronRightIcon />
                                        )}
                                    </IconButton>
                                </Box>
                                <Divider />
                                <List>                                    
                                    <ListItem button>
                                        <ListItemText
                                            sx={{ color: 'textLink.main',typography:'textLink' }}
                                            primary="Org Contacts"
                                            onClick={() =>
                                                onRedirect("/org-contacts")
                                            }
                                        />
                                    </ListItem>
                                
                                    <ListItem button>
                                        <ListItemText
                                            sx={{ color: 'textLink.main',typography:'textLink' }}
                                            primary="Rep Contacts"
                                            onClick={() =>
                                                onRedirect("/rep-contacts")
                                            }
                                        />
                                    </ListItem>                                   
                                </List>
                            </Drawer>
                        </Drawer>

                        {/* 2. rep Manager Drawer */}
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,                            
                                    marginTop: 5,
                                    },
                            }}
                            anchor="left"
                            open={state["repManagerDrawer"]}
                            onClose={toggleDrawer("repManagerDrawer", false)}
                            
                        >
                            <Box sx={drawerHeader}>
                                <Typography>
                                    <Box sx={drawerHeading}>
                                        REP Management
                                    </Box>
                                </Typography>
                                <IconButton
                                    onClick={toggleDrawer(
                                        "repManagerDrawer",
                                        false
                                    )}
                                >
                                    {theme.direction === "ltr" ? (
                                        <ChevronLeftIcon />
                                    ) : (
                                        <ChevronRightIcon />
                                    )}
                                </IconButton>
                            </Box>
                            <Divider />
                            <List>                                
                                <ListItem button>                                    
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="My Rep Network"
                                        onClick={() =>
                                            onRedirect("/rep-tracker")
                                        }
                                    />
                                </ListItem>                            
                                <ListItem button>                                    
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Rep Invite"
                                        onClick={() =>
                                            onRedirect("/rep-lists")
                                        }
                                    />
                                </ListItem>                               
                            </List>
                        </Drawer>

                        {/* 3. complaince Drawer*/}
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,                            
                                    marginTop: 5,
                                    },
                            }}
                            anchor="left"
                            open={state["complainceDrawer"]}
                            onClose={toggleDrawer("complainceDrawer", false)}
                            
                        >
                            <Box sx={drawerHeader}>
                                <Typography>
                                    <Box sx={drawerHeading}>
                                        My Complainces
                                    </Box>
                                </Typography>
                                <IconButton
                                    onClick={toggleDrawer(
                                        "complainceDrawer",
                                        false
                                    )}
                                >
                                    {theme.direction === "ltr" ? (
                                        <ChevronLeftIcon />
                                    ) : (
                                        <ChevronRightIcon />
                                    )}
                                </IconButton>
                            </Box>

                            <Divider />
                            <List>
                                
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        onClick={() => onRedirect("/subscription")}
                                        primary="Subscription"
                                    />
                                </ListItem>
                            
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="SAM IO"
                                        onClick={() => onRedirect("/sam")}
                                    />
                                </ListItem>
                            
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Company Verification"
                                        onClick={() =>
                                            onRedirect("/company-verification")
                                        }
                                    />
                                </ListItem>
                            
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Owner Driver License"
                                        onClick={() =>
                                            onRedirect("/driver-license")
                                        }
                                    />
                                </ListItem>
                            
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="W9 Tax Form"
                                        onClick={() => onRedirect("/w9")}
                                    />
                                </ListItem>
                            
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Insurance Management"
                                        onClick={() =>
                                            onRedirect("/insurance-management")
                                        }
                                    />
                                </ListItem>
                            
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Contact Management"
                                        onClick={() =>
                                            onRedirect("/contact-management")
                                        }
                                    />
                                </ListItem>
                                
                            </List>
                        </Drawer>

                        {/* 4. Review Drawer*/}
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,                            
                                    marginTop: 5,
                                    },
                            }}
                            anchor="left"
                            open={state["monitoringDrawer"]}
                            onClose={toggleDrawer("monitoringDrawer", false)}
                           
                        >
                            <Box sx={drawerHeader}>
                                <Typography>
                                    <Box sx={drawerHeading}>
                                        Review
                                    </Box>
                                </Typography>
                                <IconButton
                                    onClick={toggleDrawer(
                                        "monitoringDrawer",
                                        false
                                    )}
                                >
                                    {theme.direction === "ltr" ? (
                                        <ChevronLeftIcon />
                                    ) : (
                                        <ChevronRightIcon />
                                    )}
                                </IconButton>
                            </Box>

                            <Divider />
                            <List>
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Insurance"
                                        onClick={toggleDrawer(
                                            "reviewInsuranceSubDrawer",
                                            true
                                        )}
                                    />{" "}
                                    <ChevronRightIcon />
                                </ListItem> 
                                <ListItem button>  
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Contracts"
                                        onClick={() => onRedirect("/contract")}
                                    />
                                </ListItem>
                            </List>


                            {/* 3.1 Review Subsub Drawer */}
                            <Drawer
                                sx={{
                                    width: drawerWidth,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,                            
                                        marginTop: 5,
                                        },
                                }}
                                anchor="left"
                                open={state["reviewInsuranceSubDrawer"]}
                                onClose={toggleDrawer(
                                    "reviewInsuranceSubDrawer",
                                    false
                                )}
                               
                            >
                                <Box sx={drawerHeader}>
                                    <Typography>
                                        <Box sx={drawerHeading}>
                                            Complaince Settings
                                        </Box>
                                    </Typography>
                                    <IconButton
                                        onClick={toggleDrawer(
                                            "reviewInsuranceSubDrawer",
                                            false
                                        )}
                                    >
                                        {theme.direction === "ltr" ? (
                                            <ChevronLeftIcon />
                                        ) : (
                                            <ChevronRightIcon />
                                        )}
                                    </IconButton>
                                </Box>
                                <Divider />
                                <List>                                       
                                    <ListItem button>
                                        <ListItemText
                                            sx={{ color: 'textLink.main',typography:'textLink' }}
                                            primary="Errors & Omissons"
                                            onClick={() =>
                                                onRedirect("/review-eo")
                                            }
                                        />
                                    </ListItem>                                
                                    <ListItem button>
                                        <ListItemText
                                            sx={{ color: 'textLink.main',typography:'textLink' }}
                                            primary="General Liability"
                                            onClick={() =>
                                                onRedirect("/review-gl")
                                            }
                                        />
                                    </ListItem>
                                    <ListItem button>
                                        <ListItemText
                                            sx={{ color: 'textLink.main',typography:'textLink' }}
                                            primary="Auto"
                                            onClick={() =>
                                                onRedirect("/review-auto")
                                            }
                                        />
                                    </ListItem>                                
                                    <ListItem button>
                                        <ListItemText
                                            sx={{ color: 'textLink.main',typography:'textLink' }}
                                            primary="Workers Comp"
                                            onClick={() =>
                                                onRedirect("/review-wc")
                                            }
                                        />
                                    </ListItem>                                     
                                </List>
                            </Drawer>
                        </Drawer>

                        {/* 4. Org Management Drawer*/}
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,                            
                                    marginTop: 5,
                                    },
                            }}
                            anchor="left"
                            open={state["orgManagementDrawer"]}
                            onClose={toggleDrawer("orgManagementDrawer", false)}
                           
                        >
                            <Box sx={drawerHeader}>
                                <Typography>
                                    <Box sx={drawerHeading}>
                                        Org Management
                                    </Box>
                                </Typography>
                                <IconButton
                                    onClick={toggleDrawer(
                                        "orgManagementDrawer",
                                        false
                                    )}
                                >
                                    {theme.direction === "ltr" ? (
                                        <ChevronLeftIcon />
                                    ) : (
                                        <ChevronRightIcon />
                                    )}
                                </IconButton>
                            </Box>

                            <Divider />
                            <List>
                                
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="My ORG Network"
                                        onClick={() => onRedirect("/org-network")}
                                    />
                                </ListItem>
                            
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="ORG Invite"
                                        onClick={() => onRedirect("/org-invite")}
                                    />
                                </ListItem>
                                
                                
                            </List>
                        </Drawer>                

                    </Drawer>
                </Box>
            
        </Box>
    )
}
