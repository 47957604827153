import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarComponent from "../common/SnackbarComponent";

const axios = require("axios");

export default function OrgProfile(props) {
    const [org, setOrg] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [contacts, setContacts] = React.useState([
        {
            value: "",
            label: "",
            type: "phone",
        },
    ]);
    const [addresses, setAddresses] = React.useState([
        {
            label: "",
            address: "",
            address2: "",
            address2: "",
            city: "",
            state: "",
            zipCode: "",
        },
    ]);
    const [credProInformations, setCredProInformations] = React.useState({
        companyType: "",
        incorporationDate: "",
        EIN: "",
        DUNS: "",
    });

    const [message, setMessage] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false);
        setMessage("");
    };

    React.useEffect(() => {
        let orgId = sessionStorage.getItem("organizationId");
        axios
            .get(process.env.REACT_APP_API_URL + "/profile/org/" + orgId)
            .then((response) => {
                setOrg(response.data.data);
                if (response.data.data.contacts) {
                    setContacts(response.data.data.contacts);
                }
                if (response.data.data.addresses) {
                    setAddresses(response.data.data.addresses);
                }
                if (response.data.data.credProInformations) {
                    setCredProInformations(
                        response.data.data.credProInformations
                    );
                }
            })
            .catch((error) => {
                console.log("There was an error!", error);
                return false;
            });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        let orgId = sessionStorage.getItem("organizationId");

        // add contacts and addresses to profile
        let tmpOrg = org;
        tmpOrg.contacts = contacts;
        tmpOrg.addresses = addresses;
        tmpOrg.credProInformations = credProInformations;
        setOrg(tmpOrg);

        axios
            .post(process.env.REACT_APP_API_URL + "/profile/org/update", {
                org,
                orgId,
            })
            .then((response) => {
                setLoading(false);
                if (response.data.status) {
                    setMessage("ORG Profile saved");
                    setOpenSnackBar(true);
                    // props.history.push('/dashboard');
                } else {
                    setSeverity("error");
                    setMessage(response.data.message);
                    setOpenSnackBar(true);
                }
            })
            .catch((error) => {
                setSeverity("error");
                setMessage("Could not save details");
                setOpenSnackBar(true);
            });
    };

    // below functions to add/remove dynamic contact/address fields
    let addContact = () => {
        setContacts([...contacts, { value: "", label: "", type: "phone" }]);
    };
    let addAddress = () => {
        setAddresses([
            ...addresses,
            {
                label: "",
                address: "",
                address2: "",
                address2: "",
                city: "",
                state: "",
                zipCode: "",
            },
        ]);
    };
    let remove = (type, index) => {
        if (type == "contact") {
            let tmpContacts = [...contacts];
            tmpContacts.splice(index, 1);
            setContacts(tmpContacts);
        } else if (type == "address") {
            let tmpAddresses = [...addresses];
            tmpAddresses.splice(index, 1);
            setAddresses(tmpAddresses);
        }
    };

    return (
        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <SnackbarComponent
                        message={message}
                        openSnackBar={openSnackBar}
                        closeSnackBar={handleCloseSnackBar}
                        propsVariant={"outlined"}
                        severity={severity}
                    />
                    <Typography component="h1" variant="h3">
                        ORG Profile
                    </Typography>
                    <Typography component="h5" variant="h5" sx={{ mt: "15px" }}>
                        Organization Details
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            sx={{ mt: "0px" }}
                            name="organizationName"
                            label="ORG Name"
                            type="text"
                            value={org.organizationName || ""}
                            onChange={(e) => {
                                setOrg({
                                    ...org,
                                    organizationName: e.target.value,
                                });
                                // if(!e.target.value){
                                //   setError(true);
                                // }
                            }}
                            id="organizationName"
                            error={org.organizationName ? false : true}
                        />
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            sx={{ mt: "0px" }}
                            name="companyType"
                            label="ORG Type"
                            type="text"
                            value={credProInformations.companyType || ""}
                            onChange={(e) =>
                                setCredProInformations({
                                    ...credProInformations,
                                    companyType: e.target.value,
                                })
                            }
                            id="companyType"
                            error={
                                credProInformations.companyType ? false : true
                            }
                        />
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            sx={{ mt: "0px" }}
                            name="incorporationDate"
                            label="Incorporation Date"
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            value={credProInformations.incorporationDate || ""}
                            onChange={(e) =>
                                setCredProInformations({
                                    ...credProInformations,
                                    incorporationDate: e.target.value,
                                })
                            }
                            id="incorporationDate"
                            error={
                                credProInformations.incorporationDate
                                    ? false
                                    : true
                            }
                        />
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            sx={{ mt: "0px" }}
                            name="EIN"
                            label="EIN"
                            type="text"
                            value={credProInformations.EIN || ""}
                            onChange={(e) =>
                                setCredProInformations({
                                    ...credProInformations,
                                    EIN: e.target.value,
                                })
                            }
                            id="EIN"
                            error={credProInformations.EIN ? false : true}
                        />
                        <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            sx={{ mt: "0px" }}
                            name="DUNS"
                            label="DUNS"
                            type="text"
                            value={credProInformations.DUNS || ""}
                            onChange={(e) =>
                                setCredProInformations({
                                    ...credProInformations,
                                    DUNS: e.target.value,
                                })
                            }
                            id="DUNS"
                        />
                        <Typography
                            component="h5"
                            variant="h5"
                            sx={{ mt: "15px" }}
                        >
                            Contact Info
                        </Typography>
                        {contacts.map((contact, index) => {
                            return (
                                <span key={index}>
                                    <Typography
                                        component="p"
                                        variant="p"
                                        sx={{ mt: "0px" }}
                                    >
                                        Contact {index + 1}{" "}
                                        {index != 0 && (
                                            <Button
                                                onClick={(e) =>
                                                    remove("contact", index)
                                                }
                                                size="small"
                                                variant="text"
                                            >
                                                X
                                            </Button>
                                        )}
                                    </Typography>
                                    <Select
                                        size="small"
                                        margin="normal"
                                        required
                                        name="label"
                                        displayEmpty
                                        value={contact.label || ""}
                                        onChange={(e) => {
                                            let newContacts = [...contacts];
                                            newContacts[index].label =
                                                e.target.value;
                                            setContacts(newContacts);
                                        }}
                                        id="label"
                                        sx={{ mt: "0px", mb: "10px" }}
                                        error={contact.label ? false : true}
                                    >
                                        <MenuItem value="">
                                            Select Phone Type
                                        </MenuItem>
                                        <MenuItem value={"mobile"}>
                                            Mobile
                                        </MenuItem>
                                        <MenuItem value={"office"}>
                                            Office
                                        </MenuItem>
                                    </Select>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px" }}
                                        fullWidth
                                        name="value"
                                        label={"Contact Number " + (index + 1)}
                                        type="text"
                                        value={contact.value || ""}
                                        onChange={(e) => {
                                            let regexp = /[^0-9]/g;
                                            let newContacts = [...contacts];
                                            // if(e.target.value.match(regexp)){
                                            newContacts[index].value =
                                                e.target.value.replace(
                                                    regexp,
                                                    ""
                                                );
                                            setContacts(newContacts);
                                            // }
                                        }}
                                        id="value"
                                        error={contact.value ? false : true}
                                    />
                                </span>
                            );
                        })}
                        <Button
                            onClick={() => addContact()}
                            variant="contained"
                            size="small"
                            color="inherit"
                        >
                            + Add New
                        </Button>
                        <Typography
                            component="h5"
                            variant="h5"
                            sx={{ mt: "15px" }}
                        >
                            Company Address
                        </Typography>
                        {addresses.map((address, index) => {
                            return (
                                <span key={index}>
                                    <Typography
                                        component="p"
                                        variant="p"
                                        sx={{ mt: "0px" }}
                                    >
                                        Address {index + 1}{" "}
                                        {index != 0 && (
                                            <Button
                                                onClick={(e) =>
                                                    remove("address", index)
                                                }
                                                size="small"
                                                variant="text"
                                            >
                                                X
                                            </Button>
                                        )}
                                    </Typography>
                                    <Select
                                        size="small"
                                        required
                                        name="label"
                                        displayEmpty
                                        value={address.label || ""}
                                        onChange={(e) => {
                                            let newAddresses = [...addresses];
                                            newAddresses[index].label =
                                                e.target.value;
                                            setAddresses(newAddresses);
                                        }}
                                        id="label"
                                        sx={{ mt: "0px", mb: "10px" }}
                                        error={address.label ? false : true}
                                    >
                                        <MenuItem value="">
                                            Select Address Type
                                        </MenuItem>
                                        <MenuItem value={"home"}>Home</MenuItem>
                                        <MenuItem value={"office"}>
                                            Office
                                        </MenuItem>
                                    </Select>
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px" }}
                                        name="address1"
                                        label="Address Line 1"
                                        fullWidth
                                        type="text"
                                        value={address.address1 || ""}
                                        onChange={(e) => {
                                            let newAddresses = [...addresses];
                                            newAddresses[index].address1 =
                                                e.target.value;
                                            setAddresses(newAddresses);
                                        }}
                                        id="address1"
                                        error={address.address1 ? false : true}
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: "0px" }}
                                        name="address2"
                                        label="Address Line 2"
                                        fullWidth
                                        type="text"
                                        value={address.address2 || ""}
                                        onChange={(e) => {
                                            let newAddresses = [...addresses];
                                            newAddresses[index].address2 =
                                                e.target.value;
                                            setAddresses(newAddresses);
                                        }}
                                        id="address2"
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        sx={{ mt: "0px" }}
                                        name="address3"
                                        label="Address Line 3"
                                        fullWidth
                                        type="text"
                                        value={address.address3 || ""}
                                        onChange={(e) => {
                                            let newAddresses = [...addresses];
                                            newAddresses[index].address3 =
                                                e.target.value;
                                            setAddresses(newAddresses);
                                        }}
                                        id="address3"
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px" }}
                                        name="city"
                                        label="City"
                                        fullWidth
                                        type="text"
                                        value={address.city || ""}
                                        onChange={(e) => {
                                            let regexp =
                                                /[0-9`~!@#$%^&*()_|+\-=?;:'".<>\{\}\[\]\\\/]/g;
                                            let newAddresses = [...addresses];
                                            if (e.target.value.match(regexp)) {
                                                newAddresses[index].city =
                                                    e.target.value
                                                        .replace(regexp, "")
                                                        .toUpperCase();
                                            } else {
                                                newAddresses[index].city =
                                                    e.target.value.toUpperCase();
                                            }
                                            setAddresses(newAddresses);
                                        }}
                                        id="city"
                                        error={address.city ? false : true}
                                    />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px", width: "33%" }}
                                        name="state"
                                        label="State"
                                        fullWidth
                                        type="text"
                                        value={address.state || ""}
                                        onChange={(e) => {
                                            let regexp = /[^a-zA-Z]/g;
                                            let newAddresses = [...addresses];
                                            if (e.target.value.match(regexp)) {
                                                newAddresses[index].state =
                                                    e.target.value
                                                        .replace(regexp, "")
                                                        .toUpperCase();
                                            } else {
                                                newAddresses[index].state =
                                                    e.target.value.toUpperCase();
                                            }
                                            setAddresses(newAddresses);
                                        }}
                                        inputProps={{
                                            maxLength: 2,
                                            minLength: 2,
                                        }}
                                        id="state"
                                        error={address.state ? false : true}
                                    />
                                    <br />
                                    <TextField
                                        size="small"
                                        margin="normal"
                                        required
                                        sx={{ mt: "0px", width: "33%" }}
                                        name="zipCode"
                                        label="Postal Code"
                                        fullWidth
                                        type="text"
                                        value={address.zipCode || ""}
                                        onChange={(e) => {
                                            let regexp = /[^0-9]/g;
                                            let newAddresses = [...addresses];
                                            // if(e.target.value.match(regexp)){
                                            newAddresses[index].zipCode =
                                                e.target.value.replace(
                                                    regexp,
                                                    ""
                                                );
                                            setAddresses(newAddresses);
                                            // }
                                        }}
                                        id="zipCode"
                                        error={address.zipCode ? false : true}
                                    />
                                    <br />
                                </span>
                            );
                        })}
                        <LoadingButton
                            type="submit"
                            fullWidth
                            loading={loading}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Save
                        </LoadingButton>
                    </Box>
                </Container>
            </Box>
        </main>
    );
}
