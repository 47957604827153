import * as React from "react";
// MUI Packages
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
// Custom Components
import FileUploadComponent from "../../common/FileUploadComponent";
import FileViewerComponent from "../../common/FileViewerComponent";
import VaxStatusSection from "./VaxStatusSection";
import InstructionBottomComponent from "./InstructionBottomComponent";
import StepperConnector from "./StepperConnector";
import StepperFooterButton from "./StepperFooterButton";
import StepperSubscriptionWall from "./StepperSubscriptionWall";
import DetailRow from "./CustomDetailRow";
// Form Packages
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
let formData = new FormData();
const axios = require("axios");
// Other Imports
import { globalStyles } from "../../functions";

const MedicalExemptionData = ({
    vax_data,
    medical_exemption_data,
    setSeverity,
    setMessage,
    setOpenSnackBar,
    vaxStatus,
    setMedicalExemptionData,
    setVaxData,
    disableButtons,
    setDisableButtons,
    plan_data,
}) => {
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
    const [details, setDetails] = React.useState({});
    const [openImageModal, setOpenImageModal] = React.useState(false);
    const [imagePath, setImagePath] = React.useState("");
    const [menu, setMenu] = React.useState(null);
    const [menuIndex, setMenuIndex] = React.useState(null);
    const [rows, setRows] = React.useState([]);
    const [exemptionCertificateError, setExemptionCertificateError] =
        React.useState(false);
    const [vaxCertificationError, setVaxCertificationError] =
        React.useState(false);
    const menuOpen = Boolean(menu);

    const todayDate = new Date();
    const dd = String(todayDate.getDate()).padStart(2, "0");
    const mm = String(todayDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = todayDate.getFullYear();
    let repSubscriptionTemp = sessionStorage.getItem("repSubscription");
    if (repSubscriptionTemp) {
        repSubscriptionTemp = JSON.parse(repSubscriptionTemp);
    }
    const [repSubscription, setRepSubscription] =
        React.useState(repSubscriptionTemp);

    React.useEffect(() => {
        prepareRows(medical_exemption_data);
    }, []);

    const steps = ["Enter Exemption Data", "Make Payment"];
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const prepareRows = (data) => {
        setMedicalExemptionData(data);
        const tmpRow = [];
        if (data.length > 0) {
            if (data[data.length - 1].status == "in-review") {
                setDisableButtons(true);
            }
        }
        data.map((row, i) => {
            tmpRow.push({
                id: i + 1,
                facility_details: row.facilityDetails,
                exemption_date: row.exemptionDate,
                created_at: row.createdDate,
                ip_address: row.uploadedIPAddress,
                address:
                    row.address1 +
                    " " +
                    row.address2 +
                    " " +
                    row.city +
                    " " +
                    row.state +
                    " " +
                    row.postalCode,
                city: row.city,
                uploaded_by: vax_data.uploaded_by.first_name
                    ? vax_data.uploaded_by.first_name +
                      " " +
                      vax_data.uploaded_by.last_name
                    : vax_data.uploaded_by.email,
                exemption_certification: row.exemptionCertificate,
                vax_certification: row.vaxCertificate,
                details: row,
                status:
                    row.status == "in-review"
                        ? "In Review"
                        : row.status.charAt(0).toUpperCase() +
                          row.status.slice(1),
            });
        });
        setRows(tmpRow);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenImageModal(false);
        setOpenDetailsModal(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleMenuOpen = (event, id) => {
        setMenuIndex(id);
        setMenu(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenu(null);
    };

    const updateExemptionCertificate = (file, fileName) => {
        formData.append("exemption_certificate", fileName);
        formData.append("exemption_certificate_file", file);
        setExemptionCertificateError("");
    };
    const updateVaxCertificate = (file, fileName) => {
        formData.append("vax_certificate", fileName);
        formData.append("vax_certificate_file", file);
        setVaxCertificationError("");
    };
    const downloadVaccinationCert = () => {
        window.open(process.env.REACT_APP_VAX_CERT_URL, "_blank");
    };

    const fileValidation = () => {
        const exemption_certificate = formData.get("exemption_certificate");
        const vax_certificate = formData.get("vax_certificate");
        if (!exemption_certificate) {
            setExemptionCertificateError("Please upload Exemption Certificate");
        } else {
            setExemptionCertificateError("");
        }
        if (!vax_certificate) {
            setVaxCertificationError("Please upload Vax Certification");
        } else {
            setVaxCertificationError("");
        }
    };

    const onSubmit = async (data) => {
        const exemption_certificate = formData.get("exemption_certificate");
        const vax_certificate = formData.get("vax_certificate");
        if (!exemption_certificate) {
            setExemptionCertificateError("Please upload Exemption Certificate");
        } else {
            setExemptionCertificateError("");
        }
        if (!vax_certificate) {
            setVaxCertificationError("Please upload Vax Certification");
        } else {
            setVaxCertificationError("");
        }
        if (!exemption_certificate || !vax_certificate) {
            return false;
        }
        setLoading(true);

        let userId = sessionStorage.getItem("userId");
        const {
            facility_details,
            address_1,
            address_2,
            city,
            state,
            exemption_date,
            authoriser_firstname,
            authoriser_lastname,
            authoriser_email,
            authoriser_phone,
            postal_code,
        } = data;
        formData.append("_method", "put");
        formData.append("userId", userId);
        formData.append("facility_details", facility_details);
        formData.append("address_1", address_1);
        formData.append("address_2", address_2);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("postal_code", postal_code);
        formData.append("exemption_date", exemption_date);
        formData.append("authoriser_firstname", authoriser_firstname);
        formData.append("authoriser_lastname", authoriser_lastname);
        formData.append("authoriser_email", authoriser_email);
        formData.append("authoriser_phone", authoriser_phone);
        formData.append("loggedIn", userId);

        const config = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const response = await axios.post(
            process.env.REACT_APP_API_URL +
                "/vax-tracker/add-medical-exemption-data",
            formData,
            config
        );
        setLoading(false);
        if (response.data.status) {
            handleClose();
            setMessage("Data added");
            setOpenSnackBar(true);
            response.data.data["uploaded_by"] = vax_data.uploaded_by;
            setRepSubscription(response.data.subscription);
            if (response.data.subscription) {
                sessionStorage.setItem(
                    "repSubscription",
                    JSON.stringify(response.data.subscription)
                );
            } else {
                sessionStorage.setItem("repSubscription", "");
            }
            setVaxData(response.data.data);
            prepareRows(response.data.data["vaxTracker"]["medicalExemption"]);
        } else {
            setSeverity("error");
            setMessage(response.data.message);
            setOpenSnackBar(true);
        }
    };

    const columns = [
        {
            field: "facility_details",
            sortable: false,
            headerName: "Facility Name",
            width: 180,
        },
        {
            field: "city",
            sortable: false,
            headerName: "City",
            width: 150,
        },
        {
            field: "exemption_date",
            sortable: false,
            headerName: "Exemption Date",
            width: 150,
        },
        {
            field: "status",
            sortable: false,
            headerName: "Status",
            width: 180,
        },
        {
            field: "action",
            sortable: false,
            headerName: "Actions",
            width: 140,
            renderCell: (params) => (
                <>
                    <MoreHorizIcon
                        id="positioned-button"
                        aria-controls="positioned-menu"
                        aria-haspopup="true"
                        aria-expanded={
                            menuOpen && menuIndex == params.id
                                ? "true"
                                : undefined
                        }
                        sx={{
                            "&:hover": {
                                backgroundColor: "secondary.main",
                                color: "secondary.contrastText",
                            },
                            backgroundColor: "gray.main",
                            borderRadius: "15px",
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleMenuOpen(e, params.id)}
                    />
                    <Menu
                        id="positioned-menu"
                        aria-labelledby="positioned-button"
                        anchorEl={menu}
                        open={menuOpen && menuIndex == params.id}
                        onClose={() => handleMenuClose()}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem
                            onClick={() =>
                                imageModal(params.row.exemption_certification)
                            }
                        >
                            View Exemption Cert
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                imageModal(params.row.vax_certification)
                            }
                        >
                            View Vaccination Cert
                        </MenuItem>
                        <MenuItem onClick={() => openDetails(params.row)}>
                            Details
                        </MenuItem>
                    </Menu>
                </>
            ),
        },
    ];
    const imageModal = async (image, params) => {
        setLoading(true);
        setOpenImageModal(true);
        const res = await axios.post(
            process.env.REACT_APP_API_URL + "/fetch-image/",
            { image }
        );
        setLoading(false);
        setImagePath(res.data.data);
    };

    const openDetails = async (details) => {
        setDetails(details.details);
        setOpenDetailsModal(true);
    };

    const yupRules = {
        facility_details: yup.string().required("Facility is required"),
        address_1: yup.string().required("Address 1 is required"),
        city: yup.string().required("City is required"),
        state: yup
            .string()
            .transform((value) => value.toUpperCase())
            .required("State is required")
            .matches(/[^0-9]/g, "Only alphabets are allowed for this field ")
            .test(
                "len",
                "Must be exactly 2 characters",
                (val) => val.toString().length === 2
            ),
        postal_code: yup
            .number()
            .required("Postal Code is required")
            .typeError("Postal Code must be number")
            .test(
                "len",
                "Must be exactly 5 digits",
                (val) => val.toString().length === 5
            ),
        exemption_date: yup.string().required("Exemption Date is required"),

        authoriser_firstname: yup.string().required("First Name is required"),
        authoriser_lastname: yup.string().required("Last Name is required"),
        authoriser_phone: yup.string().required("Phone is required"),
        acceptTermsOne: yup.bool().oneOf([true], "Confirmation is required"),
        acceptTermsTwo: yup.bool().oneOf([true], "Attestation is required"),
    };
    const schemaValidation = yup.object().shape(yupRules);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schemaValidation),
    });

    return (
        <Container
            maxWidth="lg"
            align="left"
            disableGutters
            sx={{ width: "85%" }}
        >
            <Grid container>
                <Grid item xs>
                    <Typography
                        component="p"
                        variant="h5"
                        sx={{ mt: "3px", mb: "3px" }}
                    >
                        INSTRUCTIONS:
                    </Typography>
                    <Typography component="span" variant="span">
                        1. Upload your medical exception document.
                    </Typography>
                    <br />
                    <InstructionBottomComponent
                        handleOpen={() => handleOpen()}
                        disableButtons={disableButtons}
                        downloadVaccinationCert={() =>
                            downloadVaccinationCert()
                        }
                        repSubscription={repSubscription}
                        addButtonText="+ Add Medical Exemption Data"
                    />
                </Grid>
                <Grid item>
                    <VaxStatusSection
                        vaxStatus={vaxStatus}
                        vax_data={vax_data}
                        sx={{ float: "right", mt: "48px" }}
                    />
                </Grid>
            </Grid>
            <Container disableGutters sx={{ width: "100%" }}>
                <DataGrid
                    rows={rows}
                    autoHeight
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    // checkboxSelection
                    disableSelectionOnClick
                    disableColumnMenu
                />
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                sx={{
                    overflow: "scroll",
                }}
            >
                <Fade in={open}>
                    <Box
                        sx={{
                            ...globalStyles.modal,
                            minWidth: "650px",
                            top:
                                activeStep == 0
                                    ? "490px !important"
                                    : "210px !important",
                        }}
                    >
                        {repSubscription == "" && (
                            <StepperConnector
                                steps={steps}
                                activeStep={activeStep}
                            />
                        )}
                        {activeStep === steps.length ? (
                            <React.Fragment></React.Fragment>
                        ) : (
                            <form
                                encType="multipart/form-data"
                                noValidate
                                onSubmit={
                                    repSubscription
                                        ? handleSubmit(onSubmit)
                                        : activeStep == 0
                                        ? handleSubmit(handleNext)
                                        : handleSubmit(onSubmit)
                                }
                            >
                                <React.Fragment>
                                    {activeStep == 0 && (
                                        <>
                                            <Grid container>
                                                <Grid item md sx={{ m: 1 }}>
                                                    <Typography
                                                        component="h5"
                                                        variant="h5"
                                                    >
                                                        Facility:
                                                    </Typography>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="facility_details"
                                                        label="Facility Name"
                                                        type="text"
                                                        id="facility_details"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "facility_details",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.facility_details &&
                                                            errors
                                                                .facility_details
                                                                .message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="address_1"
                                                        label="Address 1"
                                                        type="text"
                                                        id="address_1"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "address_1",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.address_1 &&
                                                            errors.address_1
                                                                .message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        name="address_2"
                                                        label="Address 2"
                                                        type="text"
                                                        id="address_2"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "address_2"
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    ></FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="city"
                                                        label="City"
                                                        type="text"
                                                        id="city"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register("city", {
                                                            required: true,
                                                        })}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.city &&
                                                            errors.city.message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="state"
                                                        label="State"
                                                        type="text"
                                                        id="state"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        inputProps={{
                                                            maxLength: 2,
                                                            minLength: 2,
                                                        }}
                                                        {...register("state")}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.state &&
                                                            errors.state
                                                                .message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="postal_code"
                                                        label="Postal Code"
                                                        type="text"
                                                        id="postal_code"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        inputProps={{
                                                            maxLength: 5,
                                                            minLength: 5,
                                                        }}
                                                        {...register(
                                                            "postal_code"
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.postal_code &&
                                                            errors.postal_code
                                                                .message}
                                                    </FormHelperText>
                                                </Grid>
                                                <Grid item md sx={{ m: 1 }}>
                                                    <Typography
                                                        component="h5"
                                                        variant="h5"
                                                    >
                                                        Provider Info:
                                                    </Typography>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="authoriser_firstname"
                                                        label="Authoriser Firstname"
                                                        type="text"
                                                        id="authoriser_firstname"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "authoriser_firstname",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.authoriser_firstname &&
                                                            errors
                                                                .authoriser_firstname
                                                                .message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="authoriser_lastname"
                                                        label="Authoriser Lastname"
                                                        type="text"
                                                        id="authoriser_lastname"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "authoriser_lastname",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.authoriser_lastname &&
                                                            errors
                                                                .authoriser_lastname
                                                                .message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="authoriser_email"
                                                        label="Authoriser Email"
                                                        type="text"
                                                        id="authoriser_email"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "authoriser_email"
                                                        )}
                                                    />
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="authoriser_phone"
                                                        label="Authoriser Phone"
                                                        type="text"
                                                        id="authoriser_phone"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "authoriser_phone",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.authoriser_phone &&
                                                            errors
                                                                .authoriser_phone
                                                                .message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="exemption_date"
                                                        label="Exemption Date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            max:
                                                                yyyy +
                                                                "-" +
                                                                mm +
                                                                "-" +
                                                                dd,
                                                        }}
                                                        type="date"
                                                        id="exemption_date"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "exemption_date",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.exemption_date &&
                                                            errors
                                                                .exemption_date
                                                                .message}
                                                    </FormHelperText>
                                                    <FileUploadComponent
                                                        label="Exemption Certification"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        projectName="credpro"
                                                        currentYear={new Date()}
                                                        fileType="exemptionCertificate"
                                                        onChange={
                                                            updateExemptionCertificate
                                                        }
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {
                                                            exemptionCertificateError
                                                        }
                                                    </FormHelperText>
                                                    <FileUploadComponent
                                                        label="Vaccination Certification"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        projectName="credpro"
                                                        currentYear={new Date()}
                                                        fileType="vaccineCertification"
                                                        onChange={
                                                            updateVaxCertificate
                                                        }
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {vaxCertificationError}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    md
                                                    sx={{
                                                        m: 1,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                as={Checkbox}
                                                                control={
                                                                    control
                                                                }
                                                                color="primary"
                                                                name="acceptTermsOne"
                                                                {...register(
                                                                    "acceptTermsOne",
                                                                    {
                                                                        required: true,
                                                                    }
                                                                )}
                                                                sx={{ pt: "0" }}
                                                                defaultValue={
                                                                    false
                                                                }
                                                            />
                                                        }
                                                        sx={{
                                                            display: "block",
                                                            float: "left",
                                                            height: "100%",
                                                        }}
                                                        label=""
                                                    ></FormControlLabel>
                                                    <Typography
                                                        component="span"
                                                        variant="span"
                                                        sx={{
                                                            display: "block",
                                                            fontSize: "smaller",
                                                        }}
                                                    >
                                                        I enter this information
                                                        under penalty of perjury
                                                        which is true and
                                                        correct, and that I am
                                                        the person named. I
                                                        understand that a
                                                        knowing and willful
                                                        false statement on this
                                                        form can be punished by
                                                        fine or imprisonment or
                                                        both. Checking “I
                                                        decline to respond” does
                                                        not constitute a false
                                                        statement. I understand
                                                        that if I am a Federal
                                                        employee or contractor
                                                        making a false statement
                                                        on this form could
                                                        result in additional
                                                        administrative action
                                                        against my Client or
                                                        including an adverse
                                                        personnel action up to
                                                        and including removal
                                                        from my position or
                                                        removal from a contract.
                                                    </Typography>
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.acceptTermsOne &&
                                                            errors
                                                                .acceptTermsOne
                                                                .message}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    md
                                                    sx={{
                                                        m: 1,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                as={Checkbox}
                                                                control={
                                                                    control
                                                                }
                                                                color="primary"
                                                                name="acceptTermsTwo"
                                                                {...register(
                                                                    "acceptTermsTwo",
                                                                    {
                                                                        required: true,
                                                                    }
                                                                )}
                                                                sx={{ pt: "0" }}
                                                                defaultValue={
                                                                    false
                                                                }
                                                            />
                                                        }
                                                        sx={{
                                                            display: "block",
                                                            float: "left",
                                                            height: "100%",
                                                        }}
                                                        label=""
                                                    ></FormControlLabel>
                                                    <iframe
                                                        width="92%"
                                                        sx={{
                                                            display: "block",
                                                            height: "50px",
                                                            overflow: "auto",
                                                            fontSize: "smaller",
                                                        }}
                                                        frameBorder="0"
                                                        src={
                                                            process.env
                                                                .REACT_APP_CREDPRO_CONCENT
                                                        }
                                                    ></iframe>
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.acceptTermsTwo &&
                                                            errors
                                                                .acceptTermsTwo
                                                                .message}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    {activeStep == 1 && (
                                        <StepperSubscriptionWall
                                            plan_data={plan_data}
                                        />
                                    )}
                                    <StepperFooterButton
                                        steps={steps}
                                        activeStep={activeStep}
                                        repSubscription={repSubscription}
                                        loading={loading}
                                        activeStep={activeStep}
                                        handleClose={handleClose}
                                        fileValidation={fileValidation}
                                        handleBack={handleBack}
                                    />
                                </React.Fragment>
                            </form>
                        )}
                    </Box>
                </Fade>
            </Modal>
            <Modal
                open={openImageModal}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...globalStyles.modal }}>
                    <Grid container>
                        <Grid item md sx={{ m: 1 }}>
                            {loading ? (
                                <>
                                    <span>Fetching..</span>
                                </>
                            ) : (
                                <FileViewerComponent filePath={imagePath} />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openDetailsModal}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...globalStyles.modal, top: "50% !important" }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 400 }} aria-label="simple table">
                            <TableBody>
                                <DetailRow
                                    title={"Facility Details"}
                                    value={details.facilityDetails}
                                />
                                <DetailRow
                                    title={"IP Address"}
                                    value={details.uploadedIPAddress}
                                />
                                <DetailRow
                                    title={"Address 1"}
                                    value={details.address1}
                                />
                                <DetailRow
                                    title={"City"}
                                    value={details.city}
                                />
                                <DetailRow
                                    title={"State"}
                                    value={details.state}
                                />
                                <DetailRow
                                    title={"Postal Code"}
                                    value={details.postalCode}
                                />
                                <DetailRow
                                    title={"Authoriser Firstname"}
                                    value={details.authoriserFirstname}
                                />
                                <DetailRow
                                    title={"Authoriser Lastname"}
                                    value={details.authoriserLastname}
                                />
                                <DetailRow
                                    title={"Authoriser Phone"}
                                    value={details.authoriserPhone}
                                />
                                <DetailRow
                                    title={"Authoriser Email"}
                                    value={details.authoriserEmail}
                                />
                                <DetailRow
                                    title={"Exemption Date"}
                                    value={details.exemptionDate}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            pt: 2,
                        }}
                    >
                        <LoadingButton
                            variant="contained"
                            color="inherit"
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() => handleClose()}
                        >
                            Close
                        </LoadingButton>
                        <Box sx={{ flex: "1 1 auto" }} />
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
};

export default MedicalExemptionData;
