import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import SnackbarComponent from "../common/SnackbarComponent";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";

const axios = require("axios");

export default function Subscription() {
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [plan, setPlan] = React.useState({});
    const [subscriptionSuccessMessage, setSubscriptionSuccessMessage] =
        React.useState("");

    let organization_subscription = sessionStorage.getItem(
        "organizationSubscription"
    );
    let diffDays = 0;
    if (organization_subscription != "") {
        organization_subscription = JSON.parse(organization_subscription);
        const today = new Date();
        const current_expiry = new Date(organization_subscription.ExpiryDate);
        const diffTime = Math.abs(current_expiry - today);
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    const [autoRenew, setAutoRenew] = React.useState(
        sessionStorage.getItem("autoRenew")
    );

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false);
        setMessage("");
        setSeverity("success");
    };

    React.useEffect(() => {
        fetchPlan();
    }, []);

    const fetchPlan = () => {
        axios
            .get(process.env.REACT_APP_API_URL + "/subscriptions/fetch-plan")
            .then((response) => {
                setPlan(response.data.data);
            })
            .catch((error) => {
                console.log("There was an error!", error);
            });
    };

    const subscribe = () => {
        setLoading(true);
        let userId = sessionStorage.getItem("userId");
        console.log(userId);
        // call create payment method API
        axios
            .post(
                process.env.REACT_APP_API_URL +
                    "/subscriptions/start-subscription",
                {
                    userId,
                }
            )
            .then((response) => {
                setLoading(false);
                if (response.data.status) {
                    setMessage("Subscription Started!!");
                    sessionStorage.setItem(
                        "organizationSubscription",
                        JSON.stringify(response.data.data)
                    );
                    setSubscriptionSuccessMessage(
                        "Expires " + response.data.data.ExpiryDate
                    );
                    setOpenSnackBar(true);
                } else {
                    setSeverity("error");
                    setMessage(response.data.message);
                    setOpenSnackBar(true);
                }
            })
            .catch((error) => {
                setLoading(false);
                setSeverity("error");
                setMessage("Something went wrong, try again");
                setOpenSnackBar(true);
            });
    };

    const toggleAutorenew = () => {
        let userId = sessionStorage.getItem("userId");
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    "/subscriptions/org-toggle-autorenew/" +
                    userId
            )
            .then((response) => {
                setAutoRenew(response.data.data);
                sessionStorage.setItem("autoRenew", response.data.data);
            })
            .catch((error) => {
                console.log("There was an error!", error);
            });
    };

    return (
        <main>
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="md">
                    <SnackbarComponent
                        message={message}
                        openSnackBar={openSnackBar}
                        closeSnackBar={handleCloseSnackBar}
                        propsVariant={"outlined"}
                        severity={severity}
                    />
                    <Container maxWidth="xs">
                        <Typography
                            component="h1"
                            variant="h4"
                            sx={{ textAlign: "center" }}
                        >
                            Subscription
                        </Typography>
                        <br />
                        <Grid container>
                            <Grid item xs></Grid>
                            <Grid item>
                                Auto-renew{" "}
                                <Switch
                                    defaultChecked={
                                        autoRenew == "true" ? true : false
                                    }
                                    onChange={() => toggleAutorenew()}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                Annuaul Subscription Fee
                            </Grid>
                            <Grid item>
                                ${plan.plan_data && plan.plan_data.price}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                Sales Tax ({plan.sales_tax_per}%)
                            </Grid>
                            <Grid item>${plan.sales_tax_val}</Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs>
                                CC Processing Fee
                            </Grid>
                            <Grid item>${plan.processing_fee}</Grid>
                        </Grid>
                        <hr />
                        <Grid container sx={{ fontWeight: "bold" }}>
                            <Grid item xs>
                                Total Due
                            </Grid>
                            <Grid item>
                                $
                                {plan.plan_data &&
                                    parseFloat(plan.plan_data.total)}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                {(organization_subscription == "" ||
                                    diffDays < 30) && (
                                    <LoadingButton
                                        type="submit"
                                        loading={loading}
                                        variant="contained"
                                        size="small"
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={() => subscribe()}
                                    >
                                        Pay Subscription
                                    </LoadingButton>
                                )}
                                {organization_subscription != "" &&
                                    organization_subscription.status ==
                                        "active" &&
                                    diffDays >= 0 && (
                                        <Typography
                                            component="p"
                                            variant="p"
                                            color="primary"
                                            sx={{
                                                mt: "10px",
                                            }}
                                        >
                                            {subscriptionSuccessMessage
                                                ? subscriptionSuccessMessage
                                                : "Expires " +
                                                  organization_subscription.ExpiryDate}
                                        </Typography>
                                    )}
                                {organization_subscription != "" &&
                                    organization_subscription.status ==
                                        "expired" && (
                                        <Typography
                                            component="p"
                                            variant="p"
                                            color="primary"
                                            sx={{
                                                mt: "10px",
                                            }}
                                        >
                                            Expired
                                        </Typography>
                                    )}
                            </Grid>
                        </Grid>
                    </Container>
                </Container>
            </Box>
        </main>
    );
}
