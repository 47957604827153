import * as React from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
//import { createTheme, ThemeProvider } from '@mui/material/styles';

//const theme = createTheme();

export default function DashboardREP(props) {
    const [userId, setUserId] = React.useState(
        sessionStorage.getItem("userId")
    );

    return (
        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        CredPro Dashboard
                    </Typography>
                    <Typography
                        variant="h5"
                        align="center"
                        color="text.secondary"
                        paragraph
                    >
                        This is test Dashboard REP page for CredPro mini test
                        project.
                    </Typography>
                </Container>
            </Box>
        </main>
    );
}
