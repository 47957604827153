import React from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { FormHelperText } from '@mui/material'
import SnackbarComponent from "../common/SnackbarComponent"
import SkeletonComponent from "../common/SkeletonComponent"
import LinearProgressComponent from "../common/LinearProgressComponent"
const axios = require("axios")

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {" © "}
            <Link color="inherit" href={process.env.REACT_APP_SITE_LINK}  target="_blank">
                {" "}
                {new Date().getFullYear()}
                Ally Solutions,LLC
            </Link>
        </Typography>
    );
}



const ForgotPassword = () => {
    const yupRules = {
        email: yup
            .string()
            .required("Email is required")
            .email("Please enter valid Email"),
    }

    const schemaValidation = yup.object().shape(yupRules)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaValidation),
    })
    const [loading, setLoad] = React.useState(false)
    const [formLoading, setFormLoading] = React.useState(false)
    const [message, setMessage] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false)
    const [severity, setSeverity] = React.useState("success")

    React.useEffect(() => {        
        setLoad(false)
      },[]);

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false)
        setMessage("")
    };

    const onSubmit = async (data) => {
        setFormLoading(true)
        const { email } = data

        await axios
            .post(process.env.REACT_APP_API_URL + "/forgot-password", { email })
            .then((response) => {
                setFormLoading(false)
                if (response.data.status) {
                    setSeverity("success")
                    setMessage(response.data.message)
                    setOpenSnackBar(true)
                } else {
                    setSeverity("error")
                    setMessage(response.data.message)
                    setOpenSnackBar(true)
                }    
            })
            .catch((error) => {
                setFormLoading(false)
                //console.log(error)
                setSeverity("error")
                setMessage("Invalid Email Address!!")
                setOpenSnackBar(true)
            })
    }
   

    return (
        <Container component="main" maxWidth="xs">
            <SnackbarComponent
                message={message}
                openSnackBar={openSnackBar}
                closeSnackBar={handleCloseSnackBar}
                severity={severity}                    
            />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                {loading ? (
                    <SkeletonComponent type="circular" width={120} height={120} />
                ) : (
                    <Box>
                        <img                        
                            src={process.env.REACT_APP_LOGO}
                            alt="Logo"
                        />
                    </Box>
                )}
                <Typography component="h1" variant="h5">
                    {loading ? <SkeletonComponent type="text" width={350} height={50}  /> : "Password Recovery"}
                </Typography>
                <Box sx={{ mt: 1,  width: "100%" }}>
                    {loading ? (
                        <Box sx={{  mb: 4 }}><SkeletonComponent type="rectangular" width={390} height={400} /></Box>
                    ) : (     
                        <form                    
                            noValidate
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                size="small"
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                error={!!errors.email}
                                {...register("email")}
                            />
                            <FormHelperText sx={{ color: 'error.main',typography:'validationError' }}>{errors.email && errors.email.message}</FormHelperText>
                                {formLoading ? (
                                    <LinearProgressComponent  />
                                ) : ( 
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Submit
                                    </Button>
                                )}    
                        </form>
                    )}
                <Grid container>
                    <Grid item xs  >
                        {loading ? (
                            <SkeletonComponent type="text" width={120} height={20} />
                        ) : ( 
                            <Link
                                href="/login"
                                variant="body2"
                                color="primary"
                            >
                                Sign In
                            </Link>
                        )}    
                    </Grid>
                    <Grid item>
                        {loading ? (
                            <SkeletonComponent type="text" width={120} height={20} />
                        ) : (
                            <Link
                                href="/sign-up"
                                variant="body2"
                                color="primary"
                            >
                                Sign Up
                            </Link>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>            
        <Copyright sx={{ mt: 8, mb: 4 }}  />
            
        </Container>
    )
}
export default ForgotPassword
