import * as React from 'react'
import Box from '@mui/material/Box'

import IconButton from '@mui/material/IconButton'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import Avatar from '@mui/material/Avatar'

export default function Footer() {

  const handleChange = (event, newValue) => {  
    let userType = sessionStorage.getItem("userType")
    sessionStorage.clear()
    if (userType != null && userType != "") {
        if (userType == "ADMIN") {
          window.location.href = '/admin-login'       
        }else{
          history.go(0)
        }
    } else {      
      history.go(0)
    }
    
  }

  return (
    <Box>     
      <Box sx={{ position: 'fixed', bottom: 0, left: -3, right: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }} elevation={0} >
        <IconButton onClick={handleChange}> <Avatar sx={{ bgcolor: 'background.paper', width:30, height: 30  }}> <LogoutRoundedIcon color="primary"  /></Avatar></IconButton>
      </Box>
    </Box>
  )
}


