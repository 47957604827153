import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SnackbarComponent from "../common/SnackbarComponent";
import VaccinatedContainer from "../components/VaxTracker/VaccinatedData";
import MedicalExemptionContainer from "../components/VaxTracker/MedicalExemptionData";
import ReligiousExemptionContainer from "../components/VaxTracker/ReligiousExemptionData";
import TestingContainer from "../components/VaxTracker/TestingData";

const axios = require("axios");

export default function VaxTracker() {
    const [vax_data, setVaxData] = React.useState(null);
    const [plan_data, setPlanData] = React.useState([]);
    const [vaccine_data, setVaccineData] = React.useState([]);
    const [medical_exemption_data, setMedicalExemptionData] = React.useState(
        []
    );
    const [religious_exemption_data, setReligiousExemptionData] =
        React.useState([]);
    const [testing_data, setTestingData] = React.useState([]);
    const [vax_tracker_selection, setVaxTrackerSelection] = React.useState("");
    const [vaxStatus, setVaxStatus] = React.useState(false);
    const [disableButtons, setDisableButtons] = React.useState(false);

    React.useEffect(() => {
        fetchVaxTrackerData();
    }, []);

    const fetchVaxTrackerData = async () => {
        let userId = sessionStorage.getItem("userId");
        const response = await axios.get(
            process.env.REACT_APP_API_URL + "/vax-tracker/fetch-data/" + userId
        );
        const { data, plan_details } = response.data;
        const { vaxTracker } = data;
        setVaxData(data);
        setPlanData(plan_details);
        if (vaxTracker) {
            // vaccination
            if (vaxTracker.vaccination && vaxTracker.vaccination.length > 0) {
                setVaccineData(vaxTracker.vaccination);
            }
            // medical_exemption
            if (
                vaxTracker.medicalExemption &&
                vaxTracker.medicalExemption.length > 0
            ) {
                setMedicalExemptionData(vaxTracker.medicalExemption);
            }
            // religious_exemption
            if (
                vaxTracker.religiousExemption &&
                vaxTracker.religiousExemption.length > 0
            ) {
                setReligiousExemptionData(vaxTracker.religiousExemption);
            }
            // religious_exemption
            if (vaxTracker.testing && vaxTracker.testing.length > 0) {
                setTestingData(vaxTracker.testing);
            }
            // vaxtracker selection
            setVaxTrackerSelection(vaxTracker.vaxOptionSelected);
            // vax status
            if (vaxTracker.vaxOptionSelected == "vaccination") {
                setVaxStatus(vaxTracker.vaccinationStatus);
            } else if (vaxTracker.vaxOptionSelected == "medical_exemption") {
                setVaxStatus(vaxTracker.medicalExemptionStatus);
            } else if (vaxTracker.vaxOptionSelected == "religious_exemption") {
                setVaxStatus(vaxTracker.religiousExemptionStatus);
            } else if (vaxTracker.vaxOptionSelected == "testing") {
                setVaxStatus(vaxTracker.testingStatus);
            }
        }
    };

    const [message, setMessage] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false);
        setMessage("");
        setSeverity("success");
    };

    return (
        <main>
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="lg" align="center">
                    <SnackbarComponent
                        message={message}
                        openSnackBar={openSnackBar}
                        closeSnackBar={handleCloseSnackBar}
                        propsVariant={"outlined"}
                        severity={severity}
                    />
                    <Typography component="h1" variant="h4">
                        Vax Tracker
                    </Typography>
                    <Container maxWidth="lg" disableGutters>
                        <Button
                            onClick={() =>
                                setVaxTrackerSelection("vaccination")
                            }
                            color={
                                vax_tracker_selection == "vaccination"
                                    ? "primary"
                                    : "inherit"
                            }
                            variant="contained"
                            sx={{ m: 2 }}
                            size="small"
                            disabled={
                                vax_tracker_selection == "vaccination"
                                    ? false
                                    : disableButtons
                            }
                        >
                            Vaccinated
                        </Button>
                        <Button
                            onClick={() =>
                                setVaxTrackerSelection("medical_exemption")
                            }
                            color={
                                vax_tracker_selection == "medical_exemption"
                                    ? "primary"
                                    : "inherit"
                            }
                            variant="contained"
                            sx={{ m: 2 }}
                            size="small"
                            disabled={
                                vax_tracker_selection == "medical_exemption"
                                    ? false
                                    : disableButtons
                            }
                        >
                            Medical Exemption
                        </Button>
                        <Button
                            onClick={() =>
                                setVaxTrackerSelection("religious_exemption")
                            }
                            color={
                                vax_tracker_selection == "religious_exemption"
                                    ? "primary"
                                    : "inherit"
                            }
                            variant="contained"
                            sx={{ m: 2 }}
                            size="small"
                            disabled={
                                vax_tracker_selection == "religious_exemption"
                                    ? false
                                    : disableButtons
                            }
                        >
                            Religious Exemption
                        </Button>
                        <Button
                            onClick={() => setVaxTrackerSelection("testing")}
                            color={
                                vax_tracker_selection == "testing"
                                    ? "primary"
                                    : "inherit"
                            }
                            variant="contained"
                            sx={{ m: 2 }}
                            size="small"
                            disabled={
                                vax_tracker_selection == "testing"
                                    ? false
                                    : disableButtons
                            }
                        >
                            Testing
                        </Button>
                    </Container>
                    {vax_tracker_selection == "vaccination" && (
                        <VaccinatedContainer
                            vaccine_data={vaccine_data}
                            vax_data={vax_data}
                            setSeverity={setSeverity}
                            setMessage={setMessage}
                            setOpenSnackBar={setOpenSnackBar}
                            vaxStatus={vaxStatus}
                            setVaccineData={setVaccineData}
                            setVaxData={setVaxData}
                            disableButtons={disableButtons}
                            setDisableButtons={setDisableButtons}
                            plan_data={plan_data}
                        />
                    )}
                    {vax_tracker_selection == "medical_exemption" && (
                        <MedicalExemptionContainer
                            medical_exemption_data={medical_exemption_data}
                            vax_data={vax_data}
                            setSeverity={setSeverity}
                            setMessage={setMessage}
                            setOpenSnackBar={setOpenSnackBar}
                            vaxStatus={vaxStatus}
                            setMedicalExemptionData={setMedicalExemptionData}
                            setVaxData={setVaxData}
                            disableButtons={disableButtons}
                            setDisableButtons={setDisableButtons}
                            plan_data={plan_data}
                        />
                    )}
                    {vax_tracker_selection == "religious_exemption" && (
                        <ReligiousExemptionContainer
                            religious_exemption_data={religious_exemption_data}
                            vax_data={vax_data}
                            setSeverity={setSeverity}
                            setMessage={setMessage}
                            setOpenSnackBar={setOpenSnackBar}
                            vaxStatus={vaxStatus}
                            setReligiousExemptionData={
                                setReligiousExemptionData
                            }
                            setVaxData={setVaxData}
                            disableButtons={disableButtons}
                            setDisableButtons={setDisableButtons}
                            plan_data={plan_data}
                        />
                    )}
                    {vax_tracker_selection == "testing" && (
                        <TestingContainer
                            testing_data={testing_data}
                            vax_data={vax_data}
                            setSeverity={setSeverity}
                            setMessage={setMessage}
                            setOpenSnackBar={setOpenSnackBar}
                            vaxStatus={vaxStatus}
                            setTestingData={setTestingData}
                            setVaxData={setVaxData}
                            disableButtons={disableButtons}
                            setDisableButtons={setDisableButtons}
                            plan_data={plan_data}
                        />
                    )}
                </Container>
            </Box>
        </main>
    );
}
