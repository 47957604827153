import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const StepperConnector = ({ activeStep, steps }) => {
    const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
            fontWeight: "normal !important",
        },
        [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    "linear-gradient( 95deg,rgb(135,198,67) 0%,rgb(233,64,87) 50%,rgb(55,160,0) 100%)",
            },
        },
        [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                backgroundImage:
                    "linear-gradient( 95deg,rgb(135,198,67) 0%,rgb(233,64,87) 50%,rgb(55,160,0) 100%)",
            },
        },
        [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                theme.palette.mode === "dark"
                    ? theme.palette.grey[800]
                    : "#eaeaf0",
            borderRadius: 1,
        },
    }));

    const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
        zIndex: 1,
        color: "#fff",
        width: 50,
        height: 50,
        display: "flex",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        ...(ownerState.active && {
            backgroundColor: "#87C643",
            boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        }),
        ...(ownerState.completed && {
            backgroundColor: "#87C643",
            boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        }),
    }));

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        const icons = {
            1: <ArticleOutlinedIcon />,
            2: <AttachMoneyIcon />,
        };

        return (
            <ColorlibStepIconRoot
                ownerState={{ completed, active }}
                className={className}
            >
                {icons[String(props.icon)]}
            </ColorlibStepIconRoot>
        );
    }

    return (
        <Stepper
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            alternativeLabel
        >
            {steps.map((label, index) => {
                return (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                            {label}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default StepperConnector;
