import * as React from "react";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CachedIcon from "@mui/icons-material/Cached";
import Link from "@mui/material/Link";
import SnackbarComponent from "../../common/SnackbarComponent";
const axios = require("axios");

const VaxStatusSection = ({ vaxStatus, vax_data, sx }) => {
    const userToken = sessionStorage.getItem("userToken");
    const sessionVaxTrackerPin = sessionStorage.getItem("vaxTrackerPin");
    const [vaxTrackerPin, setVaxTrackerPin] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");
    const [copyIconClicked, setCopyIconClicked] = React.useState(false);
    const copyPublicPageURL = () => {
        setCopyIconClicked(true);
        let url = process.env.REACT_APP_URL + "/vaccine-status/" + userToken;
        let textField = document.createElement("textarea");
        textField.innerText = url;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        setOpenSnackBar(true);
        setMessage("Link Copied");
        setSeverity("success");
    };

    const refreshPin = async () => {
        // setOpenSnackBar(true);
        // setMessage("Updating PIN..");
        // setSeverity("info");
        let userId = sessionStorage.getItem("userId");
        const response = await axios.get(
            process.env.REACT_APP_API_URL + "/vax-tracker/refresh-pin/" + userId
        );
        if (response.data.status) {
            setOpenSnackBar(true);
            setMessage("PIN Updated");
            setSeverity("success");
            setVaxTrackerPin(response.data.data);
            sessionStorage.setItem("vaxTrackerPin", response.data.data);
        } else {
            setOpenSnackBar(true);
            setMessage("Error updating PIN, please try again!");
            setSeverity("error");
        }
    };

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false);
        setMessage("");
        setSeverity("success");
    };

    return (
        <Typography variant="span" align="left" sx={sx}>
            <SnackbarComponent
                message={message}
                openSnackBar={openSnackBar}
                closeSnackBar={handleCloseSnackBar}
                propsVariant={"outlined"}
                severity={severity}
            />
            <span>VAX STATUS: {vaxStatus == "pass" ? "PASS" : "FAIL"}</span>
            <br />
            <span
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                }}
            >
                <Link
                    href={
                        process.env.REACT_APP_URL +
                        "/vaccine-status/" +
                        userToken
                    }
                    target="_blank"
                    variant="body2"
                    color="primary"
                >
                    <PersonIcon
                        sx={{
                            "&:hover": {
                                color: "secondary.main",
                            },
                        }}
                    />
                </Link>
                <ContentCopyIcon
                    sx={{
                        cursor: "pointer",
                        "&:hover": {
                            color: "secondary.main",
                        },
                    }}
                    onClick={() => copyPublicPageURL()}
                    color={copyIconClicked ? "gray" : "inherit"}
                />
                PIN:
                {vaxTrackerPin
                    ? vaxTrackerPin
                    : sessionVaxTrackerPin
                    ? sessionVaxTrackerPin
                    : vax_data && vax_data.uploaded_by.vaxTrackerPin}
                <CachedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => refreshPin()}
                />
            </span>
        </Typography>
    );
};

export default VaxStatusSection;
