import { createStyles, makeStyles } from "@mui/core";

export const _forcedItems = (next) => {
    let userType = sessionStorage.getItem("userType");
    let forcedItems = sessionStorage.getItem("forcedItems");
    forcedItems = JSON.parse(forcedItems);

    if (userType == "REP") {
        if (forcedItems["profile"]) {
            next.redirect("/rep-profile");
        } else if (forcedItems["subscription"]) {
            return "/rep-profile";
        } else if (forcedItems["profilePhoto"]) {
            return "/rep-profile";
        } else if (forcedItems["driversLicense"]) {
            return "/rep-profile";
        }
        return false;
    } else if (userType == "ORG") {
        if (forcedItems["profile"]) {
            return "/rep-profile";
        } else if (forcedItems["subscription"]) {
            return "/rep-profile";
        } else if (forcedItems["profilePhoto"]) {
            return "/rep-profile";
        } else if (forcedItems["driversLicense"]) {
            return "/rep-profile";
        }
        return false;
    }
};

export const globalStyles = {
    modal: {
        position: "absolute",
        top: "66%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "7px",
        bgcolor: "background.paper",
        border: "1px solid #000",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    },
};
