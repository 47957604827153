import React from "react"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import FormControlLabel from "@mui/material/FormControlLabel"
import Checkbox from "@mui/material/Checkbox"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Container from "@mui/material/Container"
import { FormHelperText } from '@mui/material'
import SnackbarComponent from "../common/SnackbarComponent"
import SkeletonComponent from "../common/SkeletonComponent"
import LinearProgressComponent from "../common/LinearProgressComponent"
const axios = require("axios")

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {" © "}
            <Link color="inherit" href={process.env.REACT_APP_SITE_LINK}  target="_blank">
                {" "}
                {new Date().getFullYear()}
                Ally Solutions,LLC
            </Link>
        </Typography>
    )
}


const SignUp = () => {
    const yupRules = {
        email: yup
            .string()
            .email("Email is invalid")
            .required("Email is required"),
        password: yup
            .string()
            .required("Password is required")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[A-Za-z\d!@#$%^&*()\-_=+{};:,<.>]{8,}$/,
                "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            ),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Confirm Password is required"),
        acceptTerms: yup.bool().oneOf([true], "Accept Ts & Cs is required"),
    }

    const schemaValidation = yup.object().shape(yupRules)
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schemaValidation),
    })
    const [loading, setLoad] = React.useState(false)
    const [formLoading, setFormLoading] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [openSnackBar, setOpenSnackBar] = React.useState(false)
    const [severity, setSeverity] = React.useState("success")

    React.useEffect(() => {        
        setLoad(false)
      },[]);

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false)
        setMessage("")
    }

    const onSubmit = async (data) => {
        setFormLoading(true)
        const { email, password } = data

        await axios
            .post(process.env.REACT_APP_API_URL + "/register", {
                email,
                password,
            })
            .then((response) => {
                setFormLoading(false)
                if (response.data.status) {
                    setSeverity("success")
                    setMessage(
                        "A verification link has been sent to your email address"
                    )
                    setOpenSnackBar(true) 
                    window.location.href = '/login'                 
                } else {
                    setSeverity("error")
                    setMessage(response.data.message)
                    setOpenSnackBar(true)
                }
            })
            .catch((error) => {
                setFormLoading(false)
                setSeverity("error")
                setMessage(
                    "Could not able to register, please contact support"
                )
                setOpenSnackBar(true)
            })
    }
    

    return (
        <Container component="main" maxWidth="xs">
            <SnackbarComponent
                message={message}
                openSnackBar={openSnackBar}
                closeSnackBar={handleCloseSnackBar}
                severity={severity}                    
            />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
                
                {loading ? (
                    <SkeletonComponent type="circular" width={120} height={120} />
                ) : (
                <Box>
                    <img                        
                        src={process.env.REACT_APP_LOGO}
                        alt="Logo"
                    />
                </Box>
                )}

                <Typography component="h1" variant="h5">
                   {loading ? <SkeletonComponent type="text" width={350} height={50}  /> : "Account Sign Up"} 
                </Typography>

                {loading ? (
                    <Box sx={{  mb: 4 }}><SkeletonComponent type="rectangular" width={390} height={400} /></Box>
                ) : (  
                <Box sx={{ mt: 1,  width: "100%" }}>                  
                    <form
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            id="email"
                            color="primary"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            error={!!errors.email}
                            autoFocus
                            {...register("email")}
                        />
                        <FormHelperText sx={{ color: 'error.main',typography:'validationError' }}>{errors.email && errors.email.message}</FormHelperText>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            size="small"
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            color="primary"
                            error={!!errors.password}
                            autoComplete="password"
                            {...register("password")}
                            
                        />
                        <FormHelperText sx={{ color: 'error.main',typography:'validationError'}}>{errors.password && errors.password.message}</FormHelperText>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            size="small"
                            fullWidth
                            name="confirmPassword"
                            label="Confirm Password"
                            type="password"
                            id="confirmPassword"
                            color="primary"
                            error={!!errors.confirmPassword}
                            autoComplete="confirmPassword"
                            {...register("confirmPassword")}
                            
                        />
                        <FormHelperText sx={{ color: 'error.main',typography:'validationError' }}>{errors.confirmPassword && errors.confirmPassword.message}</FormHelperText>

                        {formLoading ? (
                        <LinearProgressComponent  />
                        ) : ( 
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        )}
                        
                        <Grid container>
                            <Grid item xs>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            as={Checkbox}
                                            control={control}
                                            color="primary"
                                            name="acceptTerms"
                                            {...register("acceptTerms")}
                                            defaultValue={false}
                                        />
                                    }
                                    label="Terms & Condition"
                                ></FormControlLabel>
                                
                                <FormHelperText sx={{ color: 'error.main',typography:'validationError' }}>{errors.acceptTerms && errors.acceptTerms.message}</FormHelperText>
                            </Grid>
                            <Grid item>
                                <Link
                                    href="/login"
                                    variant="body2"
                                    color="primary"
                                >
                                    Sign In
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
                )}
        </Box>            
        <Copyright  sx={{ mt: 8, mb: 4 }}  />
        </Container>
    )
}

export default SignUp
