import * as React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";

import ListItemText from "@mui/material/ListItemText";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTheme } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

const firstDrawerWidth = 40;
const drawerWidth = 220;

export default function HeaderRep() {
    const theme = useTheme();

    const drawerHeader = {
        display: "flex",
        alignItems: "center",
        p: (0, 1),
        mixins: "toolbar",
        justifyContent: "flex-end",
    };

    const drawerHeading = {
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "1rem",
    };

    // const history = useHistory()
    const onRedirect = (url) => {
        //history.push(url)
        window.location.href = url;
    };

    const toggleDrawer = (anchor, open) => (event) => {
        //console.log(anchor)
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const [state, setState] = React.useState({
        profileSubDrawer: false,
        complainceDrawer: false,
        monitoringDrawer: false,
    });

    return (
        <Box>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    bgcolor: "background.paper",
                }}
            >
                <Toolbar variant="dense" sx={{ minHeight: 40 }}>
                    <Box>
                        <Link href="/dashboard-rep">
                            <img src={process.env.REACT_APP_LOGO} alt="Logo" />
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider variant="fullWidth" sx={{ bgcolor: "common.black" }} />
            <Box>
                <Drawer
                    sx={{
                        width: firstDrawerWidth,
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            width: firstDrawerWidth,
                            boxSizing: "border-box",
                            marginTop: 5,
                            bgcolor: "primary.main",
                            paddingTop: 1.3,
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <Tooltip title="Account Management" placement="right" arrow>
                        <IconButton
                            onClick={toggleDrawer("profileSubDrawer", true)}
                        >
                            {" "}
                            <Avatar
                                sx={{
                                    bgcolor: "common.white",
                                    width: 30,
                                    height: 30,
                                }}
                            >
                                {" "}
                                <PersonOutlineIcon color="primary" />
                            </Avatar>
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="My Compliance" placement="right" arrow>
                        <IconButton
                            onClick={toggleDrawer("complainceDrawer", true)}
                        >
                            {" "}
                            <Avatar
                                sx={{
                                    bgcolor: "common.white",
                                    width: 30,
                                    height: 30,
                                }}
                            >
                                {" "}
                                <PlaylistAddCheckIcon color="primary" />
                            </Avatar>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Client Connections" placement="right" arrow>
                        <IconButton
                            onClick={toggleDrawer("monitoringDrawer", true)}
                        >
                            {" "}
                            <Avatar
                                sx={{
                                    bgcolor: "common.white",
                                    width: 30,
                                    height: 30,
                                }}
                            >
                                {" "}
                                <SwapHorizIcon color="primary" />
                            </Avatar>
                        </IconButton>
                    </Tooltip>

                    {/* 1. Profile Drawer */}
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                marginTop: 5,
                            },
                        }}
                        anchor="left"
                        open={state["profileSubDrawer"]}
                        onClose={toggleDrawer("profileSubDrawer", false)}
                    >
                        <Box sx={drawerHeader}>
                            <Typography>
                                <Box sx={drawerHeading}>Account Management</Box>
                            </Typography>
                            <IconButton
                                onClick={toggleDrawer(
                                    "profileSubDrawer",
                                    false
                                )}
                            >
                                {theme.direction === "ltr" ? (
                                    <ChevronLeftIcon />
                                ) : (
                                    <ChevronRightIcon />
                                )}
                            </IconButton>
                        </Box>
                        <Divider />
                        <List>
                            <ListItem button>
                                <ListItemText
                                    sx={{
                                        color: "textLink.main",
                                        typography: "textLink",
                                    }}
                                    onClick={() => onRedirect("/rep-profile")}
                                    primary="User Profile"
                                />
                            </ListItem>

                            <ListItem button>
                                <ListItemText
                                    sx={{
                                        color: "textLink.main",
                                        typography: "textLink",
                                    }}
                                    onClick={() =>
                                        onRedirect("/change-password")
                                    }
                                    primary="Change Password"
                                />
                            </ListItem>
                        </List>
                    </Drawer>

                    {/* 2. complaince Drawer*/}
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                marginTop: 5,
                            },
                        }}
                        anchor="left"
                        open={state["complainceDrawer"]}
                        onClose={toggleDrawer("complainceDrawer", false)}
                    >
                        <Box sx={drawerHeader}>
                            <Typography>
                                <Box sx={drawerHeading}>My Compliance</Box>
                            </Typography>
                            <IconButton
                                onClick={toggleDrawer(
                                    "complainceDrawer",
                                    false
                                )}
                            >
                                {theme.direction === "ltr" ? (
                                    <ChevronLeftIcon />
                                ) : (
                                    <ChevronRightIcon />
                                )}
                            </IconButton>
                        </Box>

                        <Divider />
                        <List>
                            <ListItem button>
                                <ListItemText
                                    sx={{
                                        color: "textLink.main",
                                        typography: "textLink",
                                    }}
                                    primary="Subscription"
                                    onClick={() =>
                                        onRedirect("/rep-subscription")
                                    }
                                />
                            </ListItem>

                            <ListItem button>
                                <ListItemText
                                    sx={{
                                        color: "textLink.main",
                                        typography: "textLink",
                                    }}
                                    primary="ID Badge"
                                    onClick={() => onRedirect("/idBadge")}
                                />
                            </ListItem>

                            <ListItem button>
                                <ListItemText
                                    sx={{
                                        color: "textLink.main",
                                        typography: "textLink",
                                    }}
                                    primary="Vax Tracker"
                                    onClick={() => onRedirect("/vax-tracker")}
                                />
                            </ListItem>

                            <ListItem button>
                                <ListItemText
                                    sx={{
                                        color: "textLink.main",
                                        typography: "textLink",
                                    }}
                                    primary="iRecord"
                                    onClick={() => onRedirect("/irecord")}
                                />
                            </ListItem>

                            <ListItem button>
                                <ListItemText
                                    sx={{
                                        color: "textLink.main",
                                        typography: "textLink",
                                    }}
                                    primary="Contracts"
                                    onClick={() => onRedirect("/contract")}
                                />
                            </ListItem>
                        </List>
                    </Drawer>

                    {/* 4. Client Connection Drawer*/}
                    <Drawer
                        sx={{
                            width: drawerWidth,
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                marginTop: 5,
                            },
                        }}
                        anchor="left"
                        open={state["monitoringDrawer"]}
                        onClose={toggleDrawer("monitoringDrawer", false)}
                    >
                        <Box sx={drawerHeader}>
                            <Typography>
                                <Box sx={drawerHeading}>Client Connections</Box>
                            </Typography>
                            <IconButton
                                onClick={toggleDrawer(
                                    "monitoringDrawer",
                                    false
                                )}
                            >
                                {theme.direction === "ltr" ? (
                                    <ChevronLeftIcon />
                                ) : (
                                    <ChevronRightIcon />
                                )}
                            </IconButton>
                        </Box>

                        <Divider />
                        <List>
                            <ListItem button>
                                <ListItemText
                                    sx={{
                                        color: "textLink.main",
                                        typography: "textLink",
                                    }}
                                    primary="Client Connections"
                                    onClick={() => onRedirect("/connection")}
                                />
                            </ListItem>
                        </List>
                    </Drawer>
                </Drawer>
            </Box>
        </Box>
    );
}
