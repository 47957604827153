import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const VaccinatedData = ({ title, value }) => {
    return (
        <TableRow
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            <TableCell
                component="th"
                scope="row"
                align="left"
                sx={{ fontWeight: "bold", p: "5px" }}
            >
                {title}
            </TableCell>
            <TableCell align="left" sx={{ p: "5px" }}>
                {value}
            </TableCell>
        </TableRow>
    );
};

export default VaccinatedData;
