import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Fade from '@mui/material/Fade'
import Slide from '@mui/material/Slide'
import Grow from '@mui/material/Grow'
import Alert from '@mui/material/Alert'

function SlideTransition(props) {
  return <Slide {...props} direction="down " />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}
  


const SnackbarComponent = ( props ) => {
  
    //console.log(props)
    //const { autoHideDuration, vertical, horizontal, severity, variant, closeSnackBar, openSnackBar,message,transition } = props      
    const autoHideDuration  = props.autoHideDuration  > 0 || props.autoHideDuration !== undefined ?  props.autoHideDuration :6000
    const vertical          = props.vertical !== undefined ? props.vertical :'top'
    const horizontal        = props.horizontal !== undefined ? props.horizontal :'center'
    const severity          = props.severity !== undefined ? props.severity: 'success'
    const variant           = props.variant !== undefined ? props.variant :'standard'
    //const transition        = props.transition !== undefined ? props.transition :'Fade'
   
    return (
    <Snackbar 
      anchorOrigin={{ vertical, horizontal }}
      open={props.openSnackBar}
      autoHideDuration={autoHideDuration}
      onClose={props.closeSnackBar} 
      TransitionComponent={SlideTransition}
      key={"SlideTransition"}
      
      >
      <Alert onClose={props.closeSnackBar} severity={severity} variant={variant}>
        {props.message}
      </Alert>
    </Snackbar>
       
    )
}

export default SnackbarComponent



