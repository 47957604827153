import * as React from "react";
// MUI Packages
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
// Custom Components
import FileUploadComponent from "../../common/FileUploadComponent";
import FileViewerComponent from "../../common/FileViewerComponent";
import VaxStatusSection from "./VaxStatusSection";
import InstructionBottomComponent from "./InstructionBottomComponent";
import StepperConnector from "./StepperConnector";
import StepperFooterButton from "./StepperFooterButton";
import StepperSubscriptionWall from "./StepperSubscriptionWall";
import DetailRow from "./CustomDetailRow";
// Form Packages
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
let formData = new FormData();
const axios = require("axios");
// Other Imports
import { globalStyles } from "../../functions";

const VaccinatedData = ({
    vax_data,
    vaccine_data,
    setSeverity,
    setMessage,
    setOpenSnackBar,
    vaxStatus,
    setVaccineData,
    setVaxData,
    disableButtons,
    setDisableButtons,
    plan_data,
}) => {
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openDetailsModal, setOpenDetailsModal] = React.useState(false);
    const [details, setDetails] = React.useState({});
    const [manufacturer, setManufacturer] = React.useState("");
    const [shot_type, setShotType] = React.useState("");
    const [openImageModal, setOpenImageModal] = React.useState(false);
    const [imagePath, setImagePath] = React.useState("");
    const [menu, setMenu] = React.useState(null);
    const [menuIndex, setMenuIndex] = React.useState(null);
    const [rows, setRows] = React.useState([]);
    const [vaxCardError, setVaxCardError] = React.useState(false);
    const [vaxCertificationError, setVaxCertificationError] =
        React.useState(false);
    const menuOpen = Boolean(menu);

    const todayDate = new Date();
    const dd = String(todayDate.getDate()).padStart(2, "0");
    const mm = String(todayDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = todayDate.getFullYear();
    let repSubscriptionTemp = sessionStorage.getItem("repSubscription");
    if (repSubscriptionTemp) {
        repSubscriptionTemp = JSON.parse(repSubscriptionTemp);
    }
    const [repSubscription, setRepSubscription] =
        React.useState(repSubscriptionTemp);

    React.useEffect(() => {
        prepareRows(vaccine_data);
    }, []);

    const steps = ["Enter Vax Data", "Make Payment"];
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const prepareRows = (data) => {
        setVaccineData(data);
        const tmpRow = [];
        if (data.length > 0) {
            if (data[data.length - 1].status == "in-review") {
                setDisableButtons(true);
            }
        }
        data.map((row, i) => {
            let fullyVaccinated = "";
            if (row.vaccineManufacturer == "Johnson & Johnson") {
                fullyVaccinated = "Yes";
            } else {
                if (row.shotType == "Vaccine 1") {
                    fullyVaccinated = "No";
                } else {
                    fullyVaccinated = "Yes";
                }
            }
            tmpRow.push({
                id: i + 1,
                shot_type: row.shotType,
                manufacturer: row.vaccineManufacturer,
                location_name: row.clinicName,
                vax_date: row.vaccinationdate,
                vax_lot: row.vaccineLot,
                created_at: row.createdDate,
                ip_address: row.uploadedIPAddress,
                fully_vaccinated: fullyVaccinated,
                address:
                    row.address1 +
                    " " +
                    row.address2 +
                    " " +
                    row.city +
                    " " +
                    row.state +
                    " " +
                    row.postalCode,
                uploaded_by: vax_data.uploaded_by.first_name
                    ? vax_data.uploaded_by.first_name +
                      " " +
                      vax_data.uploaded_by.last_name
                    : vax_data.uploaded_by.email,
                vax_card: row.vaxCard,
                vax_certification: row.vaxCertificate,
                details: row,
                status:
                    row.status == "in-review"
                        ? "In Review"
                        : row.status.charAt(0).toUpperCase() +
                          row.status.slice(1),
            });
        });
        setRows(tmpRow);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenImageModal(false);
        setOpenDetailsModal(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const handleMenuOpen = (event, id) => {
        setMenuIndex(id);
        setMenu(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenu(null);
    };

    const updateVaxCard = (file, fileName) => {
        formData.append("vax_card", fileName);
        formData.append("vax_card_file", file);
        setVaxCardError(false);
    };
    const updateVaxCertificate = (file, fileName) => {
        formData.append("vax_certificate", fileName);
        formData.append("vax_certificate_file", file);
        setVaxCertificationError(false);
    };
    const downloadVaccinationCert = () => {
        window.open(process.env.REACT_APP_VAX_CERT_URL, "_blank");
    };

    const fileValidation = () => {
        const vax_card = formData.get("vax_card");
        const vax_certificate = formData.get("vax_certificate");
        if (!vax_card) {
            setVaxCardError("Please upload Vax Card");
        } else {
            setVaxCardError(false);
        }
        if (!vax_certificate) {
            setVaxCertificationError("Please upload Vax Certification");
        } else {
            setVaxCertificationError(false);
        }
    };

    const onSubmit = async (data) => {
        const vax_card = formData.get("vax_card");
        const vax_certificate = formData.get("vax_certificate");
        if (!vax_card) {
            setVaxCardError("Please upload Vax Card");
        } else {
            setVaxCardError(false);
        }
        if (!vax_certificate) {
            setVaxCertificationError("Please upload Vax Certification");
        } else {
            setVaxCertificationError(false);
        }
        if (!vax_card || !vax_certificate) {
            return false;
        }
        setLoading(true);

        let userId = sessionStorage.getItem("userId");
        const {
            location_name,
            address_1,
            address_2,
            city,
            state,
            vax_date,
            manufacturer,
            shot_type,
            vax_lot,
            postal_code,
        } = data;
        formData.append("_method", "put");
        formData.append("userId", userId);
        formData.append("location_name", location_name);
        formData.append("address_1", address_1);
        formData.append("address_2", address_2);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("postal_code", postal_code);
        formData.append("vax_date", vax_date);
        formData.append("manufacturer", manufacturer);
        formData.append("shot_type", shot_type);
        formData.append("vax_lot", vax_lot);
        formData.append("loggedIn", userId);

        const config = {
            headers: { "Content-Type": "multipart/form-data" },
        };

        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/vax-tracker/add-vaccination-data",
            formData,
            config
        );
        setLoading(false);
        if (response.data.status) {
            handleClose();
            setMessage("Data added");
            setOpenSnackBar(true);
            response.data.data["uploaded_by"] = vax_data.uploaded_by;
            setRepSubscription(response.data.subscription);
            if (response.data.subscription) {
                sessionStorage.setItem(
                    "repSubscription",
                    JSON.stringify(response.data.subscription)
                );
            } else {
                sessionStorage.setItem("repSubscription", "");
            }
            setVaxData(response.data.data);
            prepareRows(response.data.data["vaxTracker"]["vaccination"]);
        } else {
            setSeverity("error");
            setMessage(response.data.message);
            setOpenSnackBar(true);
        }
    };

    const columns = [
        {
            field: "shot_type",
            sortable: false,
            headerName: "Shot Number",
            width: 170,
        },
        {
            field: "manufacturer",
            sortable: false,
            headerName: "Vaccine Provider",
            width: 200,
        },
        {
            field: "location_name",
            sortable: false,
            headerName: "Location Name",
            width: 180,
        },
        {
            field: "vax_date",
            sortable: false,
            headerName: "VAX Date",
            width: 150,
        },
        {
            field: "fully_vaccinated",
            sortable: false,
            headerName: "Fully Vaccinated",
            width: 180,
        },
        {
            field: "status",
            sortable: false,
            headerName: "Status",
            width: 150,
        },
        {
            field: "action",
            sortable: false,
            headerName: "Actions",
            width: 120,
            renderCell: (params) => (
                <>
                    <MoreHorizIcon
                        id="positioned-button"
                        aria-controls="positioned-menu"
                        aria-haspopup="true"
                        aria-expanded={
                            menuOpen && menuIndex == params.id
                                ? "true"
                                : undefined
                        }
                        sx={{
                            "&:hover": {
                                backgroundColor: "secondary.main",
                                color: "secondary.contrastText",
                            },
                            backgroundColor: "gray.main",
                            borderRadius: "15px",
                            cursor: "pointer",
                        }}
                        onClick={(e) => handleMenuOpen(e, params.id)}
                    />
                    <Menu
                        id="positioned-menu"
                        aria-labelledby="positioned-button"
                        anchorEl={menu}
                        open={menuOpen && menuIndex == params.id}
                        onClose={() => handleMenuClose()}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <MenuItem
                            onClick={() =>
                                imageModal(params.row.vax_card, params)
                            }
                        >
                            View Vax Card
                        </MenuItem>
                        <MenuItem
                            onClick={() =>
                                imageModal(params.row.vax_certification)
                            }
                        >
                            View Vaccination Cert
                        </MenuItem>
                        <MenuItem onClick={() => openDetails(params.row)}>
                            Details
                        </MenuItem>
                    </Menu>
                </>
            ),
        },
    ];
    const imageModal = async (image, params) => {
        setLoading(true);
        setOpenImageModal(true);
        const res = await axios.post(
            process.env.REACT_APP_API_URL + "/fetch-image/",
            { image }
        );
        setLoading(false);
        setImagePath(res.data.data);
    };

    const openDetails = async (details) => {
        setDetails(details.details);
        setOpenDetailsModal(true);
    };

    const yupRules = {
        location_name: yup.string().required("Location is required"),
        address_1: yup.string().required("Address 1 is required"),
        city: yup.string().required("City is required"),
        state: yup
            .string()
            .transform((value) => value.toUpperCase())
            .required("State is required")
            .matches(/[^0-9]/g, "Only alphabets are allowed for this field ")
            .test(
                "len",
                "Must be exactly 2 characters",
                (val) => val.toString().length === 2
            ),
        postal_code: yup
            .number()
            .required("Postal Code is required")
            .typeError("Postal Code must be number")
            .test(
                "len",
                "Must be exactly 5 digits",
                (val) => val.toString().length === 5
            ),
        vax_date: yup.string().required("Vax Date is required"),
        manufacturer: yup
            .string()
            .required("Please select Manufacturer")
            .oneOf(["Johnson & Johnson", "Pfizer", "Moderna"]),
        shot_type: yup
            .string()
            .required("Please select Shot Type")
            .oneOf(["Vaccine 1", "Vaccine 2", "Booster"]),
        vax_lot: yup.string().required("Vax Lot is required"),
        // vax_card: yup.string().required("Please upload Vax Card"),
        acceptTermsOne: yup.bool().oneOf([true], "Confirmation is required"),
        acceptTermsTwo: yup.bool().oneOf([true], "Attestation is required"),
    };
    const schemaValidation = yup.object().shape(yupRules);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schemaValidation),
    });

    return (
        <Container maxWidth="lg" align="left" disableGutters>
            <Grid container>
                <Grid item xs>
                    <Typography
                        component="p"
                        variant="h5"
                        sx={{ mt: "3px", mb: "3px" }}
                    >
                        INSTRUCTIONS:
                    </Typography>
                    <Typography component="span" variant="span">
                        1. Add 1 record for EACH VACCINE DOSE you received.
                    </Typography>
                    <br />
                    <Typography component="span" variant="span">
                        2. Add in cronological order. Vaccine 1 first, Vaccine 2
                        second, booster last.
                    </Typography>
                    <br />
                    <InstructionBottomComponent
                        handleOpen={() => handleOpen()}
                        disableButtons={disableButtons}
                        downloadVaccinationCert={() =>
                            downloadVaccinationCert()
                        }
                        repSubscription={repSubscription}
                        addButtonText="+ Add Vaccination Data"
                    />
                </Grid>
                <Grid item>
                    <VaxStatusSection
                        vaxStatus={vaxStatus}
                        vax_data={vax_data}
                        sx={{ float: "right", mt: "72px" }}
                    />
                </Grid>
            </Grid>
            <Container disableGutters sx={{ width: "100%" }}>
                <DataGrid
                    rows={rows}
                    autoHeight
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    // checkboxSelection
                    disableSelectionOnClick
                    disableColumnMenu
                />
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
                sx={{
                    overflow: "scroll",
                }}
            >
                <Fade in={open}>
                    <Box
                        sx={{
                            ...globalStyles.modal,
                            minWidth: "650px",
                            top:
                                activeStep == 0
                                    ? "465px !important"
                                    : "210px !important",
                        }}
                    >
                        {repSubscription == "" && (
                            <StepperConnector
                                steps={steps}
                                activeStep={activeStep}
                            />
                        )}
                        {activeStep === steps.length ? (
                            <React.Fragment></React.Fragment>
                        ) : (
                            <form
                                encType="multipart/form-data"
                                noValidate
                                onSubmit={
                                    repSubscription
                                        ? handleSubmit(onSubmit)
                                        : activeStep == 0
                                        ? handleSubmit(handleNext)
                                        : handleSubmit(onSubmit)
                                }
                            >
                                <React.Fragment>
                                    {activeStep == 0 && (
                                        <>
                                            <Grid container>
                                                <Grid item md sx={{ m: 1 }}>
                                                    <Typography
                                                        component="h5"
                                                        variant="h5"
                                                    >
                                                        Location:
                                                    </Typography>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="location_name"
                                                        label="Location Name"
                                                        type="text"
                                                        id="location_name"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "location_name",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.location_name &&
                                                            errors.location_name
                                                                .message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="address_1"
                                                        label="Address 1"
                                                        type="text"
                                                        id="address_1"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "address_1",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.address_1 &&
                                                            errors.address_1
                                                                .message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        name="address_2"
                                                        label="Address 2"
                                                        type="text"
                                                        id="address_2"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "address_2"
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    ></FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="city"
                                                        label="City"
                                                        type="text"
                                                        id="city"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register("city", {
                                                            required: true,
                                                        })}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.city &&
                                                            errors.city.message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="state"
                                                        label="State"
                                                        type="text"
                                                        id="state"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        inputProps={{
                                                            maxLength: 2,
                                                            minLength: 2,
                                                        }}
                                                        {...register("state")}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.state &&
                                                            errors.state
                                                                .message}
                                                    </FormHelperText>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="postal_code"
                                                        label="Postal Code"
                                                        type="text"
                                                        id="postal_code"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        inputProps={{
                                                            maxLength: 5,
                                                            minLength: 5,
                                                        }}
                                                        {...register(
                                                            "postal_code"
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.postal_code &&
                                                            errors.postal_code
                                                                .message}
                                                    </FormHelperText>
                                                </Grid>
                                                <Grid item md sx={{ m: 1 }}>
                                                    <Typography
                                                        component="h5"
                                                        variant="h5"
                                                    >
                                                        Vaccine Info:
                                                    </Typography>
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="vax_date"
                                                        label="Vax Date"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            max:
                                                                yyyy +
                                                                "-" +
                                                                mm +
                                                                "-" +
                                                                dd,
                                                        }}
                                                        type="date"
                                                        id="vax_date"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "vax_date",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.vax_date &&
                                                            errors.vax_date
                                                                .message}
                                                    </FormHelperText>
                                                    <Select
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        name="manufacturer"
                                                        fullWidth
                                                        value={
                                                            manufacturer || ""
                                                        }
                                                        displayEmpty
                                                        id="manufacturer"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "manufacturer",
                                                            {
                                                                required: true,
                                                                onChange: (
                                                                    e
                                                                ) => {
                                                                    setManufacturer(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                },
                                                            }
                                                        )}
                                                    >
                                                        <MenuItem value="">
                                                            Select Manufacturer
                                                        </MenuItem>
                                                        {vaccine_data &&
                                                        vaccine_data.length >
                                                            0 ? (
                                                            <MenuItem
                                                                value={
                                                                    vax_data
                                                                        .vaxTracker
                                                                        .vaccination[0]
                                                                        .vaccineManufacturer
                                                                }
                                                            >
                                                                {
                                                                    vax_data
                                                                        .vaxTracker
                                                                        .vaccination[0]
                                                                        .vaccineManufacturer
                                                                }
                                                            </MenuItem>
                                                        ) : null}
                                                        {vaccine_data &&
                                                        vaccine_data.length ==
                                                            0 ? (
                                                            <MenuItem value="Johnson & Johnson">
                                                                Johnson &
                                                                Johnson
                                                            </MenuItem>
                                                        ) : null}
                                                        {vaccine_data &&
                                                        vaccine_data.length ==
                                                            0 ? (
                                                            <MenuItem value="Moderna">
                                                                Moderna
                                                            </MenuItem>
                                                        ) : null}
                                                        {vaccine_data &&
                                                        vaccine_data.length ==
                                                            0 ? (
                                                            <MenuItem value="Pfizer">
                                                                Pfizer
                                                            </MenuItem>
                                                        ) : null}
                                                    </Select>
                                                    {
                                                        <FormHelperText
                                                            sx={{
                                                                color: "error.main",
                                                                typography:
                                                                    "validationError",
                                                            }}
                                                        >
                                                            {errors.manufacturer &&
                                                                errors
                                                                    .manufacturer
                                                                    .message}
                                                        </FormHelperText>
                                                    }
                                                    <Select
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        name="shot_type"
                                                        fullWidth
                                                        value={shot_type || ""}
                                                        displayEmpty
                                                        id="shot_type"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "shot_type",
                                                            {
                                                                required: true,
                                                                onChange: (
                                                                    e
                                                                ) => {
                                                                    setShotType(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                },
                                                            }
                                                        )}
                                                    >
                                                        <MenuItem value="">
                                                            Select Shot Type
                                                        </MenuItem>
                                                        {vaccine_data &&
                                                            vaccine_data.length ==
                                                                1 &&
                                                            vaccine_data[0]
                                                                .vaccineManufacturer ==
                                                                "Johnson & Johnson" && (
                                                                <MenuItem
                                                                    value={
                                                                        "Booster"
                                                                    }
                                                                >
                                                                    Booster
                                                                </MenuItem>
                                                            )}
                                                        {vaccine_data &&
                                                            vaccine_data.length ==
                                                                1 &&
                                                            vaccine_data[0]
                                                                .vaccineManufacturer !=
                                                                "Johnson & Johnson" && (
                                                                <MenuItem
                                                                    value={
                                                                        "Vaccine 2"
                                                                    }
                                                                >
                                                                    Vaccine 2
                                                                </MenuItem>
                                                            )}
                                                        {vaccine_data &&
                                                            vaccine_data.length ==
                                                                0 && (
                                                                <MenuItem
                                                                    value={
                                                                        "Vaccine 1"
                                                                    }
                                                                >
                                                                    Vaccine 1
                                                                </MenuItem>
                                                            )}
                                                        {vaccine_data &&
                                                            vaccine_data.length >
                                                                1 && (
                                                                <MenuItem
                                                                    value={
                                                                        "Booster"
                                                                    }
                                                                >
                                                                    Booster
                                                                </MenuItem>
                                                            )}
                                                    </Select>
                                                    {
                                                        <FormHelperText
                                                            sx={{
                                                                color: "error.main",
                                                                typography:
                                                                    "validationError",
                                                            }}
                                                        >
                                                            {errors.shot_type &&
                                                                errors.shot_type
                                                                    .message}
                                                        </FormHelperText>
                                                    }
                                                    <TextField
                                                        size="small"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="vax_lot"
                                                        label="Vax Lot"
                                                        type="text"
                                                        id="vax_lot"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        {...register(
                                                            "vax_lot",
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.vax_lot &&
                                                            errors.vax_lot
                                                                .message}
                                                    </FormHelperText>
                                                    <FileUploadComponent
                                                        label="Vax Card"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        projectName="credpro"
                                                        currentYear={new Date()}
                                                        fileType="vaccineCard"
                                                        onChange={updateVaxCard}
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {vaxCardError}
                                                    </FormHelperText>
                                                    <FileUploadComponent
                                                        label="Vaccination Certification"
                                                        sx={{
                                                            mt: "5px",
                                                            mb: "0px",
                                                        }}
                                                        projectName="credpro"
                                                        currentYear={new Date()}
                                                        fileType="vaccineCertification"
                                                        onChange={
                                                            updateVaxCertificate
                                                        }
                                                    />
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {vaxCertificationError}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    md
                                                    sx={{
                                                        m: 1,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                as={Checkbox}
                                                                control={
                                                                    control
                                                                }
                                                                color="primary"
                                                                name="acceptTermsOne"
                                                                {...register(
                                                                    "acceptTermsOne",
                                                                    {
                                                                        required: true,
                                                                    }
                                                                )}
                                                                sx={{ pt: "0" }}
                                                                defaultValue={
                                                                    false
                                                                }
                                                            />
                                                        }
                                                        sx={{
                                                            display: "block",
                                                            float: "left",
                                                            height: "100%",
                                                        }}
                                                        label=""
                                                    ></FormControlLabel>
                                                    <Typography
                                                        component="span"
                                                        variant="span"
                                                        sx={{
                                                            display: "block",
                                                            fontSize: "smaller",
                                                        }}
                                                    >
                                                        I enter this information
                                                        under penalty of perjury
                                                        which is true and
                                                        correct, and that I am
                                                        the person named. I
                                                        understand that a
                                                        knowing and willful
                                                        false statement on this
                                                        form can be punished by
                                                        fine or imprisonment or
                                                        both. Checking “I
                                                        decline to respond” does
                                                        not constitute a false
                                                        statement. I understand
                                                        that if I am a Federal
                                                        employee or contractor
                                                        making a false statement
                                                        on this form could
                                                        result in additional
                                                        administrative action
                                                        against my Client or
                                                        including an adverse
                                                        personnel action up to
                                                        and including removal
                                                        from my position or
                                                        removal from a contract.
                                                    </Typography>
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.acceptTermsOne &&
                                                            errors
                                                                .acceptTermsOne
                                                                .message}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid
                                                    item
                                                    md
                                                    sx={{
                                                        m: 1,
                                                        textAlign: "justify",
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                as={Checkbox}
                                                                control={
                                                                    control
                                                                }
                                                                color="primary"
                                                                name="acceptTermsTwo"
                                                                {...register(
                                                                    "acceptTermsTwo",
                                                                    {
                                                                        required: true,
                                                                    }
                                                                )}
                                                                sx={{ pt: "0" }}
                                                                defaultValue={
                                                                    false
                                                                }
                                                            />
                                                        }
                                                        sx={{
                                                            display: "block",
                                                            float: "left",
                                                            height: "100%",
                                                        }}
                                                        label=""
                                                    ></FormControlLabel>
                                                    <iframe
                                                        width="92%"
                                                        sx={{
                                                            display: "block",
                                                            height: "50px",
                                                            overflow: "auto",
                                                            fontSize: "smaller",
                                                        }}
                                                        frameborder="0"
                                                        src={
                                                            process.env
                                                                .REACT_APP_CREDPRO_CONCENT
                                                        }
                                                    ></iframe>
                                                    <FormHelperText
                                                        sx={{
                                                            color: "error.main",
                                                            typography:
                                                                "validationError",
                                                        }}
                                                    >
                                                        {errors.acceptTermsTwo &&
                                                            errors
                                                                .acceptTermsTwo
                                                                .message}
                                                    </FormHelperText>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                                    {activeStep == 1 && (
                                        <StepperSubscriptionWall
                                            plan_data={plan_data}
                                        />
                                    )}
                                    <StepperFooterButton
                                        steps={steps}
                                        activeStep={activeStep}
                                        loading={loading}
                                        repSubscription={repSubscription}
                                        handleClose={handleClose}
                                        fileValidation={fileValidation}
                                        handleBack={handleBack}
                                    />
                                </React.Fragment>
                            </form>
                        )}
                    </Box>
                </Fade>
            </Modal>
            <Modal
                open={openImageModal}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...globalStyles.modal }}>
                    <Grid container>
                        <Grid item md sx={{ m: 1 }}>
                            {loading ? (
                                <>
                                    <span>Fetching..</span>
                                </>
                            ) : (
                                <FileViewerComponent filePath={imagePath} />
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
            <Modal
                open={openDetailsModal}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...globalStyles.modal, top: "50% !important" }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 400 }} aria-label="simple table">
                            <TableBody>
                                <DetailRow
                                    title={"Location Details"}
                                    value={details.clinicName}
                                />
                                <DetailRow
                                    title={"IP Address"}
                                    value={details.uploadedIPAddress}
                                />
                                <DetailRow
                                    title={"Address 1"}
                                    value={details.address1}
                                />
                                <DetailRow
                                    title={"City"}
                                    value={details.city}
                                />
                                <DetailRow
                                    title={"State"}
                                    value={details.state}
                                />
                                <DetailRow
                                    title={"Postal Code"}
                                    value={details.postalCode}
                                />
                                <DetailRow
                                    title={"Shot Number"}
                                    value={details.shotType}
                                />
                                <DetailRow
                                    title={"Vaccine Provider"}
                                    value={details.vaccineManufacturer}
                                />
                                <DetailRow
                                    title={"Vaccination Date"}
                                    value={details.vaccinationdate}
                                />
                                <DetailRow
                                    title={"Vax Lot"}
                                    value={details.vaccineLot}
                                />
                                <DetailRow
                                    title={"Fully Vaccinated"}
                                    value={
                                        details.fullyVaccinated ? "Yes" : "No"
                                    }
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            pt: 2,
                        }}
                    >
                        <LoadingButton
                            variant="contained"
                            color="inherit"
                            size="small"
                            sx={{ mr: 1 }}
                            onClick={() => handleClose()}
                        >
                            Close
                        </LoadingButton>
                        <Box sx={{ flex: "1 1 auto" }} />
                    </Box>
                </Box>
            </Modal>
        </Container>
    );
};

export default VaccinatedData;
