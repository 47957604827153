import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const InstructionBottomComponent = ({
    handleOpen,
    disableButtons,
    downloadVaccinationCert,
    repSubscription,
    addButtonText,
}) => {
    return (
        <>
            <Button
                onClick={handleOpen}
                variant="contained"
                color="primary"
                sx={{ mr: "5px" }}
                size="small"
                disabled={disableButtons}
            >
                {addButtonText}
            </Button>
            <Button
                variant="contained"
                size="small"
                color="inherit"
                onClick={downloadVaccinationCert}
            >
                Download Vaccination Certificate
            </Button>
            {repSubscription && (
                <Typography
                    sx={{ mt: 2, mb: 1, ml: 2 }}
                    component="span"
                    variant="span"
                    color="primary"
                >
                    Subscription Exp Date: {repSubscription.ExpiryDate}
                </Typography>
            )}
        </>
    );
};

export default InstructionBottomComponent;
