import * as React from "react"
import TextField from "@mui/material/TextField"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import SnackbarComponent from "../common/SnackbarComponent"
import SkeletonComponent from "../common/SkeletonComponent"
import LinearProgressComponent from "../common/LinearProgressComponent"
import { FormHelperText } from '@mui/material'
const axios = require("axios")

export default function ChangePassword(props) {
    const yupRules = {
        email: yup
            .string()
            .email("Email is invalid")
            .required("Email is required"),
        password: yup
            .string()
            .required("Password is required")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[A-Za-z\d!@#$%^&*()\-_=+{};:,<.>]{8,}$/,
                "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            ),
    }

    const schemaValidation = yup.object().shape(yupRules)
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schemaValidation),
    })
    const [loading, setLoad] = React.useState(false)
    const [formLoading, setFormLoading] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const [openSnackBar, setOpenSnackBar] = React.useState(false)
    const [severity, setSeverity] = React.useState("success")

    React.useEffect(() => {        
        setLoad(false)
    },[]);

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false)
        setMessage("")
    }

   
   

    // form submit will be handle via below function
    const onSubmit = async(data) => {
        setFormLoading(true)
        console.log(data)
        const { email, password } = data

        // call change password API
        await axios
            .post(process.env.REACT_APP_API_URL + "/profile/change-password", {
                email,
                password,
            })
            .then((response) => {
                setFormLoading(false)
                if (response.data.status) {
                    setMessage(response.data.message)
                    setSeverity("success")                
                    setOpenSnackBar(true)
                }else{
                    setSeverity("error")
                    setMessage(response.data.message)
                    setOpenSnackBar(true)    
                }    
            })
            .catch((error) => {
                setFormLoading(false)
                setSeverity("error")
                setMessage("Invalid credentials!!")
                setOpenSnackBar(true)
            })
    }

    return (
        <main>
            {/* Hero unit */}
            <Box
                sx={{
                    bgcolor: "background.paper",
                    pt: 8,
                    pb: 6,
                }}
            >
                <Container maxWidth="sm">
                    <SnackbarComponent
                        message={message}
                        openSnackBar={openSnackBar}
                        closeSnackBar={handleCloseSnackBar}
                        propsVariant={"outlined"}
                        severity={severity}
                    />
                    <Typography component="h1" variant="h3">
                        {loading ? <SkeletonComponent type="text" width={350} height={50}  /> : "Change Password"} 
                    </Typography>
                    <Typography component="span" variant="span">
                        {loading ? <SkeletonComponent type="text" width={350} height={50}  /> : "Enter email and current password. A change password link will be emailed to you."} 
                    </Typography>
                    {loading ? (
                        <Box sx={{  mb: 4 }}><SkeletonComponent type="rectangular" width={390} height={400} /></Box>
                    ) : ( 
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                        >
                            <TextField
                                size="small"
                                margin="normal"
                                fullWidth
                                name="email"
                                label="Email"
                                type="email"                            
                                id="email"
                                error={!!errors.email} 
                                {...register("email")}                           
                            />
                            <FormHelperText sx={{ color: 'error.main',typography:'validationError' }}>{errors.email && errors.email.message}</FormHelperText>
                            <TextField
                                size="small"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                error={!!errors.password} 
                                {...register("password")}
                                
                            />
                            <FormHelperText sx={{ color: 'error.main',typography:'validationError' }}>{errors.password && errors.password.message}</FormHelperText>
                                {formLoading ? (
                                    <LinearProgressComponent  />
                                ) : ( 
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Submit
                                    </Button>
                                )}    
                        </Box>
                    )}
                </Container>
            </Box>
        </main>
    )
}
