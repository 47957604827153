import React from "react";

const FileViewerComponent = ({ filePath }) => {
    if (filePath.includes(".pdf")) {
        return <iframe height="700" width="100%" src={filePath}></iframe>;
    } else {
        return <img height="100%" width="100%" src={filePath} />;
    }
};

export default FileViewerComponent;
