import * as React from "react"
import { useHistory } from "react-router-dom"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import ListItem from "@mui/material/ListItem"
import Link from "@mui/material/Link"

import ListItemText from "@mui/material/ListItemText"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

import { useTheme } from '@mui/styles'
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"

import IconButton from "@mui/material/IconButton"
import PersonOutlineIcon from "@mui/icons-material/PersonOutline"
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import Avatar from "@mui/material/Avatar"
import Tooltip from '@mui/material/Tooltip'
import Badge from '@mui/material/Badge'

import { styled } from '@mui/material/styles'

const firstDrawerWidth = 40
const drawerWidth = 220

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 2,
      top: 25,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const StyleMainNavdBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right:5,
      top:13,
      padding:'5.5px',
      borderRadius: '5.5px',     
    }
  }));  
export default function HeaderAdmin() {
    const theme = useTheme()

    const drawerHeader= {
        display: "flex",
        alignItems: "center",
        p: (0, 1),
        mixins:"toolbar",
        justifyContent: "flex-end",
    }

    const drawerHeading= {
        textAlign: "left",
        fontWeight: "bold",
        fontSize: "1rem",
    }

    

    

    //const history = useHistory()
    const onRedirect = (url) => {
        //history.push(url)
        window.location.href = url
    }

    const toggleDrawer = (anchor, open) => (event) => {
        //console.log(anchor)
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return
        }

        setState({ ...state, [anchor]: open })
    }

    const [state, setState] = React.useState({
        profileSubDrawer: false,
        monitoringDrawer: false,
        reviewSubSubDrawer: false,
    })

    const [vaccinationCounters, setVaccinationCounters] = React.useState({
        vaccinations: 4,
        medicalExemptions: 6,
        religiousExemptions: 3,
        testings: 6,
    })

    return (
        <Box>
            <AppBar
                position="fixed"
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 , bgcolor: "background.paper"}}
            >
                <Toolbar variant="dense" sx={{minHeight: 40}}>
                    <Box>
                        <Link href="/dashboard-admin">
                            <img
                                src={process.env.REACT_APP_LOGO}
                                alt="Logo"
                            />
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
            <Divider variant="fullWidth" sx={{bgcolor:"common.black"}} />            
                <Box>
                    <Drawer
                        sx={{
                            width: firstDrawerWidth,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                            width: firstDrawerWidth,
                            boxSizing: 'border-box',
                            marginTop: 5,
                            bgcolor: "primary.main",
                            paddingTop: 1.3,
                            },
                        }}
                        
                        variant="permanent"
                        anchor="left"
                    >
                        <Tooltip title="Account Management" placement="right" arrow>
                            <IconButton
                                onClick={toggleDrawer("profileSubDrawer", true)}
                            >
                                {" "}
                                <Avatar
                                    sx={{ bgcolor: "common.white", width: 30, height: 30 }}
                                >
                                    {" "}
                                    <PersonOutlineIcon color="primary" />
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                       
                        <Tooltip title="Review" placement="right" arrow>
                            <StyleMainNavdBadge color="error" variant="dot">
                                <IconButton
                                    onClick={toggleDrawer("monitoringDrawer", true)}
                                >
                                    {" "}
                                    <Avatar
                                        sx={{ bgcolor: "common.white", width: 30, height: 30 }}
                                    >
                                        {" "}
                                        <FindInPageOutlinedIcon color="primary" />
                                    </Avatar>
                                </IconButton>
                            </StyleMainNavdBadge>
                        </Tooltip>
                        
                        {/* 1. Profile Drawer */}
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,                            
                                    marginTop: 5,
                                    },
                            }}
                            anchor="left"
                            open={state["profileSubDrawer"]}
                            onClose={toggleDrawer("profileSubDrawer", false)}
                            
                        >
                            <Box sx={drawerHeader}>
                                <Typography>
                                    <Box sx={drawerHeading}>
                                        Account Management
                                    </Box>
                                </Typography>
                                <IconButton
                                    onClick={toggleDrawer(
                                        "profileSubDrawer",
                                        false
                                    )}
                                >
                                    {theme.direction === "ltr" ? (
                                        <ChevronLeftIcon />
                                    ) : (
                                        <ChevronRightIcon />
                                    )}
                                </IconButton>
                            </Box>
                            <Divider />
                            <List>
                                   
                                <ListItem button>
                                    <ListItemText 
                                    sx={{ color: 'textLink.main',typography:'textLink' }} 
                                    onClick={() => onRedirect("/admin-profile")}
                                    primary="Admin Profile"
                                    />                                    
                                </ListItem>
                                
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="SubUser"
                                        onClick={() => onRedirect("/admin-sub-user")}
                                    />
                                </ListItem>
                                
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="User Role"
                                        onClick={() => onRedirect("/user-role")}
                                    />
                                </ListItem>
                                
                                    <ListItem button>
                                        <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        onClick={() => onRedirect("/change-password")}
                                        primary="Change Password"
                                        />
                                    </ListItem>
                                         
                                
                            </List>

                            

                           
                        </Drawer>

                       

                        {/* 4. Review Drawer*/}
                        <Drawer
                            sx={{
                                width: drawerWidth,
                                '& .MuiDrawer-paper': {
                                    width: drawerWidth,                            
                                    marginTop: 5,
                                    },
                            }}
                            anchor="left"
                            open={state["monitoringDrawer"]}
                            onClose={toggleDrawer("monitoringDrawer", false)}
                           
                        >
                            <Box sx={drawerHeader}>
                                <Typography>
                                    <Box sx={drawerHeading}>
                                        Review
                                    </Box>
                                </Typography>
                                <IconButton
                                    onClick={toggleDrawer(
                                        "monitoringDrawer",
                                        false
                                    )}
                                >
                                    {theme.direction === "ltr" ? (
                                        <ChevronLeftIcon />
                                    ) : (
                                        <ChevronRightIcon />
                                    )}
                                </IconButton>
                            </Box>

                            <Divider />
                            <List>                                
                                <ListItem button>
                                    <ListItemText
                                        sx={{ color: 'textLink.main',typography:'textLink' }}
                                        primary="Vax Tracker"
                                        onClick={toggleDrawer(
                                            "reviewSubSubDrawer",
                                            true
                                        )}
                                    />{" "}
                                    <ChevronRightIcon />
                                </ListItem>
                            </List>

                            {/* 2.1 Vax Tracker Sub Drawer */}
                            <Drawer
                                sx={{
                                    width: drawerWidth,
                                    '& .MuiDrawer-paper': {
                                        width: drawerWidth,                            
                                        marginTop: 5,
                                        },
                                }}
                                anchor="left"
                                open={state["reviewSubSubDrawer"]}
                                onClose={toggleDrawer(
                                    "reviewSubSubDrawer",
                                    false
                                )}
                               
                            >
                                <Box sx={drawerHeader}>
                                    <Typography>
                                        <Box sx={drawerHeading}>
                                            Vax Tracker Review
                                        </Box>
                                    </Typography>
                                    <IconButton
                                        onClick={toggleDrawer(
                                            "reviewSubSubDrawer",
                                            false
                                        )}
                                    >
                                        {theme.direction === "ltr" ? (
                                            <ChevronLeftIcon />
                                        ) : (
                                            <ChevronRightIcon />
                                        )}
                                    </IconButton>
                                </Box>
                                <Divider />
                                <List>
                                    <StyledBadge badgeContent={vaccinationCounters["vaccinations"]} color="secondary" >   
                                        <ListItem button>
                                            <ListItemText
                                                sx={{ color: 'textLink.main',typography:'textLink' }}
                                                primary="Vaccinated"
                                                onClick={() =>
                                                    onRedirect("/vaccination-review")
                                                }
                                            />
                                        </ListItem>
                                    </StyledBadge>  

                                    <StyledBadge badgeContent={vaccinationCounters["medicalExemptions"]} color="secondary" >
                                        <ListItem button>
                                            <ListItemText
                                                sx={{ color: 'textLink.main',typography:'textLink' }}
                                                primary="Medical Exemption"
                                                onClick={() =>
                                                    onRedirect("/medical-exemption-review")
                                                }
                                            />
                                        </ListItem>
                                    </StyledBadge>
                                    
                                    <StyledBadge badgeContent={vaccinationCounters["religiousExemptions"]} color="secondary">
                                        <ListItem button>
                                            <ListItemText
                                                sx={{ color: 'textLink.main',typography:'textLink' }}
                                                primary="Religious Exemption"
                                                onClick={() =>
                                                    onRedirect("/religious-exemption-review")
                                                }
                                            />
                                        </ListItem>  
                                    </StyledBadge> 

                                    <StyledBadge badgeContent={vaccinationCounters["testings"]} color="secondary" >
                                        <ListItem button>
                                            <ListItemText
                                                sx={{ color: 'textLink.main',typography:'textLink' }}
                                                primary="Testing"
                                                onClick={() =>
                                                    onRedirect("/testing-review")
                                                }
                                            />
                                        </ListItem>
                                    </StyledBadge>

                                </List>
                            </Drawer>
                        </Drawer>

                                     

                    </Drawer>
                </Box>
            
        </Box>
    )
}
