import * as React from "react";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { useParams } from "react-router";
import SnackbarComponent from "../common/SnackbarComponent";
import FileViewerComponent from "../common/FileViewerComponent";

const axios = require("axios");

export default function VaccineStatus(props) {
    const { token } = useParams();
    const userdata = sessionStorage.getItem("userdata");

    const yupRules = {
        pin: yup
            .number()
            .required("PIN is required")
            .typeError("PIN must be number")
            .test(
                "len",
                "PIN must be exactly 6 digits",
                (val) => val.toString().length === 6
            ),
    };

    const schemaValidation = yup.object().shape(yupRules);
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
    } = useForm({
        resolver: yupResolver(schemaValidation),
    });

    const [message, setMessage] = React.useState("");
    const [openSnackBar, setOpenSnackBar] = React.useState(false);
    const [severity, setSeverity] = React.useState("success");

    const handleCloseSnackBar = (event, reason) => {
        setOpenSnackBar(false);
        setMessage("");
    };

    const [pin, setPin] = React.useState("");
    const [vaxCard, setVaxCard] = React.useState("");
    const [vaxCert, setVaxCert] = React.useState("");
    const [vaxStatus, setVaxStatus] = React.useState(false);
    const [name, setName] = React.useState(false);
    const [verified, setVerified] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    // form submit will be handle via below function
    const onSubmit = (data) => {
        setLoading(true);
        // call verify pin API
        axios
            .get(
                process.env.REACT_APP_API_URL +
                    "/vax-tracker/verify-pin/" +
                    token +
                    "/" +
                    pin
            )
            .then((response) => {
                console.log(response.data.data);
                const { data } = response.data;
                setVaxStatus(data.vaccination_status);
                setName(data.name);
                setVaxCard(data.vaxCard);
                setVaxCert(data.vaxCertificate);
                setLoading(false);
                setVerified(true);
            })
            .catch((error) => {
                setLoading(false);
                setMessage("Invalid credentials!!");
                setVerified(false);
                console.log("There was an error!", error);
            });
    };

    return (
        <Container component="main" maxWidth="md">
            <SnackbarComponent
                message={message}
                openSnackBar={openSnackBar}
                closeSnackBar={handleCloseSnackBar}
                propsVariant={"outlined"}
                severity={severity}
            />
            <div
                style={{
                    marginTop: "64px",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div>
                    <img
                        className="header-logo"
                        src={process.env.REACT_APP_LOGO}
                        alt="Logo"
                    />
                </div>
                <Typography component="h1" variant="h5">
                    VAX Tracker
                </Typography>
                {!verified && (
                    <form
                        style={{
                            marginTop: "8px",
                            width: "50%",
                        }}
                        noValidate
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <TextField
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            name="pin"
                            label="PIN"
                            type="password"
                            value={pin || ""}
                            id="pin"
                            {...register("pin", {
                                required: true,
                                onChange: (e) => setPin(e.target.value),
                            })}
                            helperText={errors.pin && errors.pin.message}
                        />
                        <LoadingButton
                            type="submit"
                            fullWidth
                            loading={loading}
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Verify
                        </LoadingButton>
                    </form>
                )}
                {verified && (
                    <Container
                        disableGutters
                        sx={{
                            height: "auto",
                            mt: "5px",
                            textAlign: "center",
                        }}
                    >
                        <Typography component="p" variant="p">
                            Name: {name ? name : "Not Given"}
                        </Typography>
                        <Typography component="p" variant="p">
                            Status: {vaxStatus == "pass" ? "PASS" : "FAIL"}
                        </Typography>
                        <Grid container sx={{ mb: "15px" }}>
                            <Grid item md sx={{ m: 1 }}>
                                <Typography
                                    component="p"
                                    variant="p"
                                    sx={{ float: "left" }}
                                >
                                    VAX Card:
                                </Typography>
                                <FileViewerComponent filePath={vaxCard} />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mb: "15px" }}>
                            <Grid item md sx={{ m: 1 }}>
                                <Typography
                                    component="p"
                                    variant="p"
                                    sx={{ float: "left" }}
                                >
                                    VAX Certificate:
                                </Typography>
                                <FileViewerComponent filePath={vaxCert} />
                            </Grid>
                        </Grid>
                    </Container>
                )}
            </div>
        </Container>
    );
}
