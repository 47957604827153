import React from 'react'
import Skeleton from '@mui/material/Skeleton'

const SkeletonComponent = ( props ) => {

    
    return (
        
        <Skeleton variant={props.type} width={props.width} height={props.height}  {...props} />
        
             
       
    )
}

export default SkeletonComponent



